import React, {useEffect, useState} from 'react';
import { Link, useParams, useNavigate, Navigate } from 'react-router-dom';

const NativeLoginSuccessful = () => {
     
    const navigate = useNavigate(); 
    const urlSearchString = window.location.search; 
    const params = new URLSearchParams(urlSearchString);   
    localStorage.setItem("distributorId", params.get('distributorId'));
    localStorage.setItem('loginToken', params.get('token'));                    
    localStorage.setItem("distributorDisplayName", params.get('distributorName'));
    localStorage.setItem("userType", params.get('userType'));
    localStorage.setItem("companyIdBooster", params.get('companyIdBooster'));
    localStorage.setItem("companyIdParis", params.get('companyIdParis')); 
    <Navigate to="/" />
    navigate('/');
    window.location.replace('/');   

  return (
     <div id="overlayNativeLogin">
        <div className="spinner"></div>
            <br/>
            Loading...
    </div> 
  )
}

export default NativeLoginSuccessful