import React from 'react'
import GetRequest from '../Services/APIRequest'
 

const FarmerInAreaBox = () => {
   
  const distID = localStorage.getItem('distributorId');
  const boostID = localStorage.getItem('companyIdBooster');
  const parisID = localStorage.getItem('companyIdParis'); 

  const { data } = GetRequest(`/Farmer/GetNumberOfFarmersInDistributorsArea/${distID}`);
  
  if(data.result !== '' &&  data.result !== 0) {
  return (
    
    <div className="col-lg-3 col-6">
        <div className="small-box scol3">
            <div className="inner">
                <h4>{data.result}</h4> 
                <p>Farmers In Your area</p>
            </div>
            <div className="icon">
                <i className="fa fa-group"></i>
            </div>
            
        </div>
    </div>
  )
} else {
  return (
    <> </>
   )
}

}

export default FarmerInAreaBox