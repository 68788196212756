import React from 'react'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import { Link, Outlet } from 'react-router-dom'


const RaiseQueryDashboard = () => {
    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');

    const { data, error, isLoaded } = GetRequest(`/Queries/SearchQueries/${distID}`);

    if (error !== null) {
        return <Error data=" My Previous Queries " />;
    }
    if (!isLoaded) {
        return <Loading />;
    }
    if (data.result.length !== 0) {
        return (
            <>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">My Previous Queries </h3>  <Link to="/CreateQuery" className='btn btn-success fright'>Raise Query</Link>
                                    </div>

                                    <div className="card-body">
                                        <div className="row">
                                            {
                                                data.result && data.result.map((item, index) => (

                                                    <div className="col-md-4">
                                                        <div className="card card-danger">
                                                            <div className={`card-header ${item.status ? 'bg-danger' : 'bg-success'}`}>
                                                                <h5 className="card-title">   {item.id} </h5>
                                                            </div>
                                                            <div className="card-body">
                                                                {/* <label>Type : {item.queryByType}</label><br /> */}
                                                                <label>Details : {item.details}</label><br />
                                                                <label>Status : <b>{item.status}</b></label><br />
                                                                <label>Resolved On : {item.resolvedOn}</label><br />
                                                                <label> Resolved By : {item.resolvedBy}</label><br />
                                                                <label> Resolution Details :{item.resolutionDetails}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Outlet></Outlet>
                        </div>
                    </div>
                </section>
            </>
        )
    } else {
        return (
            <>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-warning">
                                    <div className="card-header">
                                        <h3 className="card-title">My Previous Queries </h3>  <Link to="/CreateQuery" className='btn btn-primary fright'>Raise Query</Link>
                                    </div>

                                    <div className="card-body">
                                        <h4 className="NoDataAvailableText"> No Data Available </h4>
                                    </div>
                                </div>
                            </div>
                            <Outlet></Outlet>
                        </div>
                    </div>
                </section>
            </>
        )
    }

}

export default RaiseQueryDashboard