import React from 'react'
import LiveSugImg from '../Assets/img/whitegold1.png';
import newOrderImg from '../Assets/img/Icon/newordericon.png';
import newpaymentImg from '../Assets/img/Icon/newpaymenticon.jpg';
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import { Link } from 'react-router-dom';

function FarmerSuggetions() {

  const { data, error, isLoaded } = GetRequest("/Event/GetLatestFarmerLiveSuggestions");

  if (error !== null) {
    return <Error data="Farmer Live Suggestions" />;
  }
  if (!isLoaded) {
    return <Loading />;
  }

  if (data.result!==null &&data.result.length !== 0) {
    return (
      <div className="col-md-6">
        <div className="card card-primary">
          <div className="card-header widget-user-header" id="accordion-no">
            {/* <div className="widget-user-image">
              <img className="img-circle elevation-2" src={LiveSugImg} alt="Live" width="50" />
            </div> */}
            <h6 className="widget-user-username" data-bs-toggle="collapse" href='#collapse-no-head'>Farmer Live Suggestions <br/>
            <p className="card-title">{data.result.name} <br/> {data.result.eventDateString}</p>  
                <i className="fa fa-angle-down  dp-right-mt5"></i>
                </h6>   
          </div>

          <div className="card-body no-padding collapse show"  id="collapse-no-head"  data-bs-parent="#accordion-no">
            <ul className="nav flex-column">
              <div id="accordion" className='farmerLiveHomeUI'>
                {
                  data.result.recommandations && data.result.recommandations.map(item => ( 
                    <div className="card card-liveSuggCol">
                      <div className="card-header collapsed"  data-bs-toggle="collapse" href={`#collapse${item.id}`}> 
                        
                        <label className='lblhead'> {item.title} ({item.languageName}) </label> 
                           <i className="fa fa-plus  dp-right-mt5"></i>
                           <i className="fa fa-minus  dp-right-mt5"></i>
                      </div>
                      <div id={`collapse${item.id}`} className="collapse" data-bs-parent="#accordion">
                        <div className="card-body  no-padding">
                        <p className='pdetls'> 
                          <b>Problems :- </b>{item.fieldProblemTitle}                            
                          {data.result.enableSharing == true ? <>
                                   <a href={`whatsapp://send?text=${item.messageForSharing}`}  data-action="share/whatsapp/share"  
                                   className="botao-wpp-HomeIcon" target="_blank">
                                <i className="fa fa-whatsapp whatsappIconShare"></i>
                            </a>                            
                                </>
                                    : <a  data-action="share/whatsapp/share"  
                                    className="botao-wpp-HomeIcon" target="_blank">
                                 <i className="fa fa-whatsapp whatsappIconShare"></i>
                             </a>}                             
                            <br/>    
                            <br />
                          <b>Problem Type :- </b>{item.problemGroupTitle} <br />
                          <b>Suggestions :- </b>{item.suggestion}.<br />
                          </p> 
                            <h6 className='subTitle sinleIconDisplay'> Recommended Product  </h6>
                            {
                              item.recommendedProductLines && item.recommendedProductLines.map(items => (
                                <p className='pdetls'> 
                                     <b>Product Name :- </b> {items.productName}<br />
                                     <b>Dosage Information :- </b>  {items.dosageInformationPerPump}.<br />
                                     <b>Dosage Info Per Acre :- </b> {items.dosageInformationPerAcre}.<br />
                                     <b>Comments  :- </b> {items.comments}.<br />
                                     <div className='col-12 combineProductIcon'> 
                                        <i className="fa fa-plus PlusIcon"></i>
                                     </div>                                    
                                </p>
                              ))
                            } 
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </ul>
          </div>
          <div className="card-footer">
            <Link to="/NewOrder"><img className="img-circle iconbtn" src={newOrderImg} title="Place New Order" width="50" /></Link>
            <Link to="/NewPayment/SubmitPayment">  <img className="img-circle iconbtn" src={newpaymentImg} title="Add New Payment" width="50" /></Link>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="col-md-6">
        <div className="card card-warning">
          <div className="card-header widget-user-header">
            <h3 className="card-title">Farmer Live Suggestions</h3>
          </div>
          <div className="card-body">
            <table className="table table-sm">
              <tbody>
                <tr>
                  <td className="NoDataAvailableText"> No Data Available </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default FarmerSuggetions