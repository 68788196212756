
import React from 'react'
import { useState } from 'react';
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'

const OrderTransactionDetails = () => {

    const orlineId = localStorage.getItem("ditsCurrentOrderLineId");
    const { data, error, isLoaded } = GetRequest(`/Orders/GetOrderLineSummary/${orlineId}`);
    if (error !== null) {
        return <Error data=" " />;
    }
    if (!isLoaded) {
        return <Loading />;
    } else {
        localStorage.removeItem('OredrCartProductData');
    }

    return (
        <>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card card-warning">
                                <div className="card-header">
                                    <h3 className="card-title">Order Successful - Thank You.</h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            {/* <h4 className='text-blue'>Order Details</h4>  */}
                                            
                                            <div className="row">
                                                <div className="col-sm-6">
                                                <p className='text-grey'><b className='text-grey-bold'>Order# : </b>{data.result.orderNumber} </p>
                                                {/* <p><b className='text-grey-bold'>Status : </b>{data.result.status} </p> */}
                                                <p><b className='text-grey-bold'>Number of Items : </b>{data.result.numberOfItems}</p>
                                                </div>
                                                <div className="col-sm-6">
                                                <p className='text-grey'><b className='text-grey-bold'>By/For : </b>{data.result.distributorName} </p>
                                                {/* <p><b className='text-grey-bold'>Order    : </b> {data.result.orderPlacedDate.slice(0, 10)} </p> */}
                                                <p><b className='text-grey-bold'>Ship To   : </b> {data.result.shipToDetails} </p>                                                
                                               </div>
                                               <div className="col-sm-12">                                               
                                                <p><b className='text-grey-bold'>Remarks   : </b> {data.result.orderComments} </p>                                                
                                               </div>
                                            </div> 
                                            {
                                                data.result.orders && data.result.orders.map((item, index) => (
                                                    <>                                                     
                                                        <table className='table table-striped '>
                                                        <caption style={{captionSide: "top"}}  ><span className='text-grey-bold'>  <b className='text-grey-bold'>{item.companyName} Products</b></span>   <hr className='invLine'></hr></caption>
                                                            {/* <tr>
                                                                <th className='text-grey-bold '>
                                                                    No.
                                                                </th>
                                                                <th className='text-grey-bold '>
                                                                    Product Name
                                                                </th>
                                                                <th className='text-grey-bold '>
                                                                     Quantity 
                                                                </th>
                                                            </tr> */}
                                                            <tbody>
                                                                {
                                                                    item.orderLineItemDTOList && item.orderLineItemDTOList.map((items, index) => (
                                                                        <>
                                                                            <tr>
                                                                                {/* <td>
                                                                                    {index + 1}
                                                                                </td> */}
                                                                                <td>
                                                                                    {items.productName}
                                                                                </td>
                                                                                <td>
                                                                                    {items.totalQuantityOrdered}
                                                                                </td>
                                                                            </tr>

                                                                        </>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>

                                                    </>
                                                ))
                                            } 
                                              
                                            {/* <table className='table table-striped '>
                                                <tr>
                                                    <td style={{ background: "#f1f1f1", borderTop: "1px solid #bee1ff"  }}>   <h5 style={{ float: "right", background: "#f1f1f1"  }} className='text-grey-bold '>Total : {data.result.totalWithTax}</h5> </td>
                                                </tr>
                                            </table> */}
                                          
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OrderTransactionDetails
