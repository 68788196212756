 import React from 'react' 

const BPDemoRoute = () => {
     const label = "Demo Title"
     
    return (
        <>
            <section className="content">
                <div className="container-fluid">                 
                    <div className="row">
                        <div className="col-12">  
                        <h1>{label}</h1>
                        </div>                     
                 </div>
                </div>
            </section>          
        </>
    )
}

export default BPDemoRoute