 
import React from 'react' 
import { Link, useParams } from 'react-router-dom';
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error' 


const OrderDetails = () => {
    const Id = useParams();
    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis'); 
    
    const { data, error, isLoaded } = GetRequest(`/Orders/GetOrderLineDetailsById/${Id.Id}`); 
    if (error !== null) {
        return <Error data=" " />;
    }
    if (!isLoaded) {
        return  <Loading />;
    } 

    return (
        <>

            <section className="content">
                <div className="container-fluid">
                 
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    {/* <h3 className="card-title"> Orders  Details - {data.result && data.result.orderNumber}</h3>    */}
                                    <h3 className="card-title">{data.result && data.result.orderNumber}</h3>   
                                </div> 
                                <div className="card-body">
                                <p>   Status - <b>{data.result.statusDisplayText}</b>  </p>
                                <p>   Ship To - <b>{data.result.shipToDetails}</b>  </p>
                                <div className="col-sm-12">                                   
                                   <div className="form-group">
                                   <p>   Remarks: <b>{data.result.orderComments}</b>  </p>                                           
                                   </div>
                                   <div className="form-group">
                                   <p>   Approval Comments: <b>{data.result.orderStatusChangeComments}</b>  </p>
                                   </div>
                                   {data.result.orderApprovedBy != null && data.result.orderApprovedBy != '' ? 
                                   <div className="form-group">
                                   <p>   Approved by: <b>{data.result.orderApprovedBy}</b>  </p>
                                   </div>     :'' }                              
                               </div>
                                {
                                    
                                        data.result.orders && data.result.orders.map((item, index) => ( 
                                            <div className="row"> 
                                                <div className="col-md-12">
                                                    <div className="card ">
                                                        <div className="card-header bg-info">
                                                            <h5 className="card-title">  {item.companyName} Order ({item.numberOfItemsDispatched}/{item.numberOfItems}) </h5>
                                                        </div>
                                                        <div className="card-body">
                                                         {/* <h6>Number Of Items - {item.numberOfItems} </h6>  
                                                         <h6>  Number Of Items dispatched - {item.numberOfItemsDispatched} </h6>                                                     */}
                                                         
                                                           {
                                                                item.orderLineItemDTOList && item.orderLineItemDTOList.map((items, index) => ( 
                                                                    <>
                                                                        <table className='table table-bordered'>
                                                                            {/* <thead>
                                                                                <th style={{width: "100%"}}>
                                                                                Item
                                                                                </th>
                                                                                <th>
                                                                                Dispatched/Total
                                                                                </th>
                                                                            </thead> */}
                                                                            <tbody> <tr>
                                                                                <td> {items.productName}</td>
                                                                                <td>{items.dispatchedQuantity} / {items.totalQuantityOrdered}</td>
                                                                            </tr> </tbody>
                                                                        </table>
                                                                    </>
                                                                ))
                                                            }                                                            
                                                        </div>
                                                    </div>
                                                </div>  
                                            </div> 
                                        ))
                                    }
                            </div>
                        </div>
                    </div> 
                 </div>

                </div>
            </section>
        </>
    )
}

export default OrderDetails