import React from 'react'
import { Outlet } from "react-router-dom";
import Header from '../Layouts/Header';
import BPUSidebar from './BPUSidebar';
import Footer from '../Layouts/Footer';   
import { Navigate } from 'react-router-dom';

function sidebarOff(){  
  if(window.innerWidth < 991){
    document.querySelector('.boostapp').classList.remove('active');  
  }
}
 
 
const checkLoginToken = localStorage.getItem('loginToken');

const BPUDashboard = ( ) => {

  // const urlSearchString = window.location.search; 
  // if(urlSearchString !== ""){
  //     const params = new URLSearchParams(urlSearchString);   
  //     const Btoken = "Bearer " + params.get('token')
  //     localStorage.setItem("distributorId", params.get('distributorId'));
  //     localStorage.setItem('loginToken', Btoken);                    
  //     localStorage.setItem("distributorDisplayName", params.get('distributorName'));
  //     localStorage.setItem("userType", params.get('userType'));
  //     localStorage.setItem("companyIdBooster", params.get('companyIdBooster'));
  //     localStorage.setItem("companyIdParis", params.get('companyIdParis')); 
  // }
  

  // if (checkLoginToken === null && checkLoginToken != '') {
  //   return <Navigate to="/Login" />;
  // }  
 
  return (
    <>
      <Header />
      <BPUSidebar />
      <div className='content-wrapper' onClick={sidebarOff}> 
        <div className='container-fluid'> 
            <Outlet className="drop-in"></Outlet>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default BPUDashboard
 