import React from 'react'
import PageTopHeadTitleBread from '../Components/PageTopHeadTitleBread'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error' 

// const { data, error, isLoaded } = GetRequest("/Inventory/GetInventory/12");

// if (error !== null) {
//   return <Error data=" " />;
// }
// if (!isLoaded) {
//   return  <Loading />;
// } 
const CartDashboard = () => {
  return (
    <>
      <PageTopHeadTitleBread title={"Cart"} />

   </>
  )
}

export default CartDashboard