import React from 'react'
import { Link } from 'react-router-dom'

const MoreInformationDashboard = () => {
    return (
        <>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card card-primary" >
                                <div className="card-header">
                                    <h3 className="card-title">More Information</h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                    <Link to="/Profitability" className="btn btn-app scol3 col-lg-3 col-6" >
                                            <i className="fa fa-gift"></i> Profitability
                                       </Link>
                                       <Link to="/WorkGuidence" className="btn btn-app scol3 col-lg-3 col-6" >
                                            <i className="fa fa-users"></i> Work Guidance-Manage Farmers
                                       </Link>
                                       <Link to="/WorkGuidenceEvents" className="btn btn-app scol3 whe110 col-lg-3 col-6 " >
                                            <i className="fa fa-user-circle-o"></i> Work Guidance-Add Area/ Plan / Request/ Share Events
                                       </Link>
                                       <Link to="/CropWiseReccomandations" className="btn btn-app scol5 col-lg-3 col-6" >
                                            <i className="fa fa-info-circle"></i> Crop Wise Recommendations
                                       </Link>
                                       <Link to="/ChannelObservationList" className="btn btn-app scol1 col-lg-3 col-6" >
                                            <i className="fa fa-address-card-o"></i> Share Observations
                                       </Link>
                                        <Link to="/ExpiringStock" className="btn btn-app scol4 col-lg-3 col-6" >
                                            <i className="fa fa-stack-exchange"></i> Expiring Stock
                                        </Link>
                                        <Link to="/ProductNeverOrder"  className="btn btn-app scol2 col-lg-3 col-6 " >
                                            <i className="fa fa-cart-plus "></i> Products Never Ordered  By You
                                        </Link>
                                       
                                       {/* <Link to="/Tips" className="btn btn-app scol1 col-lg-3 col-6" >
                                            <i className="fa fa-book"></i> Tips
                                       </Link> */}
                                       {/* <Link to="/Articles" className="btn btn-app scol2 col-lg-3 col-6" >
                                            <i className="fa fa-newspaper-o"></i> Articles
                                       </Link> */}
                                       <Link to="/Greetings" className="btn btn-app scol3 col-lg-3 col-6" >
                                            <i className="fa fa-gift"></i> Greetings
                                       </Link>
                                       
                                       <Link to="/Videos" className="btn btn-app  scol4 col-lg-3 col-6" >
                                            <i className="fa fa-youtube-square"></i> Videos
                                       </Link>
                                       <Link to="/Yashogatha" className="btn btn-app scol2 col-lg-3 col-6" >
                                            <i className="fa fa-newspaper-o"></i> Yashogatha/ Farmer Success  Stories
                                       </Link>
                                       <Link to="/OurTeamContacts" className="btn btn-app scol3 col-lg-3 col-6" >
                                            <i className="fa fa-address-card "></i> Our Team Contacts
                                       </Link>
                                      
                                       {/* <Link to="/DelearFinance" className="btn btn-app scol1 col-lg-3 col-6" >
                                            <i className="fa fa-inr"></i> Dealer Loans/Dealer<br /> Finance
                                       </Link> */}
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MoreInformationDashboard