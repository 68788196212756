import React, { useState } from 'react'
import axios from 'axios';
import '../Assets/css/login.css';
import logo from '../Assets/img/logo.png';
import { Link, useNavigate, Navigate } from 'react-router-dom'; 

const Logout = () => {
    localStorage.removeItem('loginToken');
    localStorage.removeItem('distributorId');
    const logoutUser = () => {  
        return <Navigate to="/Login" />; 
    }

    return (
        <section className="body">
            <div className="container">
                <div className="login-box drop-in">
                    <div className="row">
                        <div className="col-md-12 loginUrllog">
                        <div className="logo">
                                <img src={logo} alt="Booster Paris" width={120} />
                            </div> 
                            <h1> Thank You!</h1>
                            <i className="fa fa-lock checkmark"></i>
                            <p>
                                 Please click here to login again - <Link to="/Login" onClick={logoutUser} >Login </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Logout