 
import React from 'react';
import Navigation from './Routes/Navigation';

function App() { 
  return (
    <div className='boostapp'>  
      <Navigation />
    </div>
  );
}

export default App;
