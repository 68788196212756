import React from 'react'
import { Link, useParams } from 'react-router-dom';
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import $ from 'jquery';

const SchemesDetails = () => {

    const Id = useParams();
    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');

    const { data, error, isLoaded } = GetRequest(`/Scheme/GetSchemeDetails/${Id.Id}`);

    if (error !== null) {
        return <Error data=" Scheme Details" />;
    }
    if (!isLoaded) {
        return <Loading />;
    }

    const appendopeningStatement = (props) => {
        const  htmlData = props; 
        setTimeout(function() {
            $('#openingStatementData').html('').append(htmlData);
        }, 500) 
    }
    const appendregistrationBenefit = (props) => {
        const  htmlData = props; 
        setTimeout(function() {
            $('#appendregistrationBenefitData').html('').append(htmlData);
        }, 500) 
    }
    const appendpaymentBenefits = (props) => {
        const  htmlData = props; 
        setTimeout(function() {
            $('#appendpaymentBenefitsData').html('').append(htmlData);
        }, 500) 
    }
    const appendtermsAndConditions = (props) => {
        const  htmlData = props; 
        setTimeout(function() {
            $('#appendtermsAndConditionsData').html('').append(htmlData);
        }, 500) 
    }
    return (
        <>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-primary">
                                <div className="card-header ">
                                    <h4 className="card-title"> {data.result.name}</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-12  col-md-12 col-lg-8 order-1 order-md-1">
                                                     
                                                    <div className="post">
                                                        <h5> {data.result.name} </h5>
                                                        <p>Scheme No :  {data.result.schemeNumber} </p>
                                                        <p>Date:  {data.result.fromDateString} to  {data.result.toDateString}</p>
                                                        <p>Scheme For : <b className='btn-info'> {data.result.productCategoryName} ({data.result.season})</b> </p>
                                                        <p>   </p>
                                                        <div className='formobileOnly'>
                                                        <div className="row">
                                                                        <div className="col-sm-6">
                                                        <div className="card card-danger">
                                                        <div className="card-header">
                                                            <h5 className="card-title">Cash Discount-Seed Booking</h5>
                                                        </div>

                                                        <div className="card-body"> 
                                                                    <div className="row">
                                                                        <div className="col-sm-12">
                                                                            {
                                                                                data.result.schemeDTODateDetailsItems && data.result.schemeDTODateDetailsItems.map((items, index) => (
                                                                                    <div className='discRow'>
                                                                                        {items.fromDateString} To {items.toDateString}
                                                                                        - Discount : <b>{items.discountAmount}%</b>  
                                                                                        <br />
                                                                                    </div>

                                                                                ))
                                                                            }
                                                                              
                                                                        </div>
                                                                       
                                                                    </div>
                                                                    </div>
                                                    </div>
 
                                                        </div>
                                                    
                                                                        <div className="col-sm-6">
                                                    <div className="card card-primary">
                                                        <div className="card-header">
                                                            <h5 className="card-title">Important Documents Download</h5>
                                                        </div>

                                                        <div className="card-body"> 
                                                                    <div className="row">
                                                                        
                                                                        <div className="col-sm-12">
                                                                            {
                                                                                data.result.schemeDTODocumentDetailsItems && data.result.schemeDTODocumentDetailsItems.map((itdocs, index) => (
                                                                                    <div className='discRow'>
                                                                                        {itdocs.documentType} :  {itdocs.schemeDocumentName} 
                                                                                        <a href={itdocs.schemeDocumentURL} target='_blank' title='Download File'> <i className="fa fa-file-pdf-o downPdfLk"></i></a>
                                                                                        <br />
                                                                                    </div>

                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </div>
 
                                                                    </div>
                                                    </div>
                                                        </div>
                                                    </div>
                                                    
                                                        </div>
                                                        <div id="accordion" className='schemesUIDts'>
                                                            <div className="card card-gray">
                                                                <div className="card-header  " data-bs-toggle="collapse" href="#openingStatement">
                                                                    
                                                                        <label className="pHeadNameLink"> Opening Statement  </label>
                                                                        <i className="fa fa-angle-down faconProLinkNoMarg"></i>
                                                                </div>
                                                                <div id="openingStatement" className="collapse show" data-bs-parent="#accordion">
                                                                    <div className="card-body"> 
                                                                        <p onLoad={appendopeningStatement(data.result.openingStatement)} id='openingStatementData'></p> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card card-success">
                                                                <div className="card-header" data-bs-toggle="collapse" href="#registrationBenefit">
                                                                    
                                                                        <label className="pHeadNameLink"> Registration Benefit </label>
                                                                        <i className="fa fa-angle-down faconProLinkNoMarg"></i>
                                                                </div>
                                                                <div id="registrationBenefit" className="collapse show" data-bs-parent="#accordion">
                                                                    <div className="card-body">
                                                                    <p onLoad={appendregistrationBenefit(data.result.registrationBenefit)} id='appendregistrationBenefitData'></p> 
                                                                         
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="card card-primary">
                                                                <div className="card-header" data-bs-toggle="collapse" href="#paymentBenefits">
                                                                    
                                                                        <label className="pHeadNameLink"> Payment Benefit </label>
                                                                        <i className="fa fa-angle-down faconProLinkNoMarg"></i>
                                                                </div>
                                                                <div id="paymentBenefits" className="collapse show" data-bs-parent="#accordion">
                                                                    <div className="card-body">
                                                                    <p onLoad={appendpaymentBenefits(data.result.paymentBenefits)} id='appendpaymentBenefitsData'></p> 
                                                                      
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card card-gray">
                                                                <div className="card-header"  data-bs-toggle="collapse" href="#termsAndConditions">
                                                                   
                                                                        <label className="pHeadNameLink"> Terms And Conditions</label>
                                                                        <i className="fa fa-angle-down faconProLinkNoMarg"></i>
                                                                </div>
                                                                <div id="termsAndConditions" className="collapse show" data-bs-parent="#accordion">
                                                                    <div className="card-body">
                                                                    <p onLoad={appendtermsAndConditions(data.result.termsAndConditions)} id='appendtermsAndConditionsData'></p> 
                                                                         
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-4 order-2 order-md-2"> 

                                                    {/* <div className="card card-danger">
                                                        <div className="card-header">
                                                            <h5 className="card-title">Cash Discount-Seed Booking</h5>
                                                        </div>

                                                        <div className="card-body"> 
                                                                    <div className="row">
                                                                        <div className="col-sm-12">
                                                                            {
                                                                                data.result.schemeDTODateDetailsItems && data.result.schemeDTODateDetailsItems.map((items, index) => (
                                                                                    <div className='discRow'>
                                                                                        {items.fromDateString} To {items.toDateString}
                                                                                        - Discount : <b>{items.discountAmount}%</b>  
                                                                                        <br />
                                                                                    </div>

                                                                                ))
                                                                            }
                                                                              
                                                                        </div>
                                                                       
                                                                    </div>
 
 
                                                        </div>
                                                    </div>
                                                    <div className="card card-primary">
                                                        <div className="card-header">
                                                            <h5 className="card-title">Important Documents Download</h5>
                                                        </div>

                                                        <div className="card-body"> 
                                                                    <div className="row">
                                                                        
                                                                        <div className="col-sm-12">
                                                                            {
                                                                                data.result.schemeDTODocumentDetailsItems && data.result.schemeDTODocumentDetailsItems.map((itdocs, index) => (
                                                                                    <div className='discRow'>
                                                                                        {itdocs.documentType} :  {itdocs.schemeDocumentName} 
                                                                                        <a href={itdocs.schemeDocumentURL} target='_blank' title='Download File'> <i className="fa fa-file-pdf-o downPdfLk"></i></a>
                                                                                        <br />
                                                                                    </div>

                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </div>
 
 
                                                        </div>
                                                    </div> */}
                                                    
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SchemesDetails