import React from 'react'
import PageTopHeadTitleBread from '../Components/PageTopHeadTitleBread'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error' 
import demoImgCrop from '../Assets/img/cropDemo.jpeg'


const CropDashboard = () => {
  
  const { data, error, isLoaded } = GetRequest("/Crop/GetCropList");

if (error !== null) {
  return <Error data=" " />;
}
if (!isLoaded) {
  return  <Loading />;
} 
  return (
    <>
      <PageTopHeadTitleBread title={"Crops "} />
      <section className="content">
            <div className="container-fluid">  
                <div className="row"> 
                  <div className="card"> 
                     <div className="row card-body">
                          { 
                              data.result.map((item, index) => ( 
                                <div className="col-sm-3"> 
                                  <div className="card">
                                      <img className="card-img-top" src={demoImgCrop} alt="Crop image" width="100%" />
                                      <div className="card-body">
                                        <h4 className="card-title">{item.name}</h4>
                                        <p className="card-text">{item.description}</p>
                                        <a href className="btn btn-primary dp-right">Details</a>
                                      </div>
                                  </div>
                                      
                                </div>
                              ))
                          }   
                     </div>
                   </div>
                </div>
            </div>
       </section>
    </>
  )
}

export default CropDashboard