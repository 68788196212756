import React from 'react'
import GetRequest, { BASE_URL1 } from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import { Link, useParams } from 'react-router-dom';


const PaymentSummaryDescription = () => {
    const pID = document.getElementById('paymentIdResData').value
    const { data, error, isLoaded } = GetRequest("/payment/GetPaymentSummaryByPaymentId/" + pID);
    if (error !== null) {
        return <Error data=" Payment Summary " />;
    }
    if (!isLoaded) {
        return <Loading />;
    }

    document.getElementById('Payment-submit-stepper-head').classList.remove('active');
    document.getElementById('Payment-distribution-stepper-head').classList.remove('active');
    document.getElementById('Payment-summary-stepper-head').classList.add('active');

    localStorage.setItem('currentSelectedCompId', localStorage.getItem('companyIdBooster'));

    return (
        <div className='row'>
            <div className="col-lg-6">
                <div className="card card-primary">
                    <div className="card-header  ">
                        <h3 className="card-title">Payment Summary</h3>
                        <Link to="/" className='btn btn-success fright'>Home</Link>
                        <Link to="/MyPayments/MyPaymentsList" className='btn btn-success fright' style={{ marginRight: '15px' }} >Back to My Payments</Link>
                    </div>
                    <div className="card-body">
                        <table className="table table-responsive">
                            <tbody>
                                {
                                    data.result.schemeDistributionDetails && data.result.schemeDistributionDetails.map((item, index) => (
                                        <tr>
                                            <td> <h6>{item.schemName}</h6> </td>
                                            <td>{item.paidAmountForScheme} </td>
                                        </tr>
                                    ))
                                }
                                {
                                    data.result.costCentreWisePaymentDistributionDetails && data.result.costCentreWisePaymentDistributionDetails.map((item, index) => (
                                        <tr>
                                            <td> <h6>Cost Centre Name : {item.costCentreDisplayName}</h6> </td>
                                            <td>{item.paidAmountAgainstCostCentre} </td>
                                        </tr>
                                    ))
                                }
                                {
                                    data.result.invoiceDistributionDetails && data.result.invoiceDistributionDetails.map((item, index) => (
                                        <tr>
                                            <td> <h6>Invoice# : {item.invoiceNumber}</h6> </td>
                                            <td>{item.paidAmountAgainstInvoice} </td>
                                        </tr>
                                    ))
                                }

                                <tr>
                                    <td> <h6>Total Payment : </h6> </td>
                                    <td>{data.result.totalPayment} </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="card card-gray">
                    <div className="card-header  ">
                        <h3 className="card-title">Transaction Details</h3>
                    </div>
                    <div className="card-body">
                        <table className="table table-responsive">
                            <tbody>
                                <tr>
                                    <td> <h6> Receipt#  </h6> </td>
                                    <td> - {data.result.portalVoucherNumber} </td>
                                </tr>


                                <tr>
                                    <td> <h6> Transaction Id/Cheque#</h6> </td>
                                    <td> - {data.result.transactionId} </td>
                                </tr>
                                <tr>
                                    <td> <h6> To Bank</h6> </td>
                                    <td> - {data.result.toBankDetails} </td>
                                </tr>

                                <tr>
                                    <td> <h6>Details: </h6> </td>
                                    <td> -  </td>
                                </tr>
                                <tr>
                                    <td> <h6> Date</h6> </td>
                                    <td> - {data.result.paymentDateStr} </td>
                                </tr>
                                <tr>
                                    <td> <h6>Attachment </h6> </td>
                                    <td> -  {data.result.paymentReceiptURL ? <a href={`${BASE_URL1}/Payment/downloadFile?fileName=${data.result.paymentReceiptURL}`} download>Download</a> : "-"} </td>

                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    )


}

export default PaymentSummaryDescription