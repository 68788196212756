import React from 'react'

const Loading = () => {
  return (
        <div className="col-md-6 drop-in"> 
            <div className="card"> 
                <div className="card-body tH">  
                        <span className="loader"></span>
                </div> 
            </div> 
        </div> 
  )
}

export default Loading