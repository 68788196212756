
import React from 'react'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import { useNavigate, useParams } from "react-router-dom";

const PaymentsAgainOutstandingsEdit = (props) => {

    const Id = useParams();
    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');

    const { data, error, isLoaded } = GetRequest(`/Payment/GetCostCentresForOutstandingCollectionForPaymentEdit/${Id.Id}`);

    if (error !== null) {
        return <Error data="Payment Against Outstandings " />;
    }
    if (!isLoaded) {
        return <Loading />;
    }
    console.log(Id.Id);

    return (
        <>
            <div id="accordion1">
                <h5 className='invHead' data-bs-toggle="collapse" href={`#collapseAgainsOut`} >Payment Against Outstandings
                    <a className="btn dp-right-mt5 accUIIcon">
                        <i className="fa fa-angle-double-down"></i>
                        <i className="fa fa-angle-double-up hide"></i>
                    </a>
                </h5>
                <div id={`collapseAgainsOut`} className="collapse" data-bs-parent="#accordion1">
                    {
                        data.result && data.result.map((item, index) => (
                            <div className='row sepSecLineOutAmt'>
                                <div className='col-sm-4'>
                                    <p> {item.costCentreDisplayName}</p>
                                </div>
                                <div className='col-sm-4'>
                                    <p><b>{item.costCentreName} </b> </p>
                                    <p> {item.costCentreDetails}</p>
                                </div>

                                <div className='col-sm-4'>
                                    <input type='number' className='form-control payOutText' id={item.costCentreId} 
                                    name='Amount' onBlur={props.funcname}  defaultValue={item.paidAmountAgainstCostCentre}/>
                                </div>
                            </div>
                        ))
                    }

                </div>
            </div>

        </>
    )

}

export default PaymentsAgainOutstandingsEdit