import React from 'react'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import { Link } from 'react-router-dom'

const AllRunningSchemes = () => {

    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');

    const { data, error, isLoaded } = GetRequest(`/Scheme/GetRunningSchemes/${distID}`);
    
    if (error !== null) {
        return <Error data="  All Running Schemes" />;
    }
    if (!isLoaded) { 
        return <Loading />;
    } else {
        addFilter();
    }

    function addFilter() {
        setTimeout(function () {
            let list = document.querySelectorAll('.listfilterhead');
            let itemBox = document.querySelectorAll('.itemfiltered');

            for (let i = 0; i < list.length; i++) {
                list[i].addEventListener('click', function () {
                    for (let j = 0; j < list.length; j++) {
                        list[j].classList.remove('active');
                    }
                    this.classList.add('active');

                    let dataFilter = this.getAttribute('data-filter');

                    for (let k = 0; k < itemBox.length; k++) {
                        itemBox[k].classList.remove('active');
                        itemBox[k].classList.add('hide');

                        if (itemBox[k].getAttribute('data-item') == dataFilter || dataFilter == "all") {
                            itemBox[k].classList.remove('hide');
                            itemBox[k].classList.add('active');
                        }
                    }
                })
            }
        }, 1000);
    }

    if (data.result.length !== 0) {
        return (
            <>
                {
                    data.result && data.result.map((item, index) => (
                        <div className="card itemfiltered hide card-primary" data-item="11" id={item.id}>
                            <div className="card-header"  data-bs-toggle="collapse" href={`#collapse${item.id}`}>

                                <label className="pHeadName">{item.name}</label>

                                <a className="btn dp-right-mt5">
                                    <i className="fa fa-angle-double-down"></i>
                                    <i className="fa fa-angle-double-up"></i>
                                </a>
                            </div>
                            <div id={`collapse${item.id}`} className="collapse" data-bs-parent="#accordion">
                                <div className="card-body dtss">
                                    <div className="row ">
                                        <div className="col-sm-2 col2">
                                            Active Till : {item.toDateString}
                                        </div>
                                        <div className="col-sm-2 col2">
                                            {item.productCategoryName}
                                        </div>

                                        <div className="col-sm-4 col4">
                                            {
                                                item.schemeDTODateDetailsItems && item.schemeDTODateDetailsItems.map((items, index) => (
                                                    
                                                        <div className='discRow'>
                                                            {items.fromDateString} To {items.toDateString}
                                                            - Discount : <b>{items.discountAmount}%</b>  
                                                            <br />
                                                        </div>
                                                ))
                                            }
                                            <Link to={`/SchemesDetails/${item.id}`} className='btn btn-success btn-sm '  >View Details </Link>

                                        </div>
                                        <div className="col-sm-4 col4">
                                            {
                                                item.schemeDTODocumentDetailsItems && item.schemeDTODocumentDetailsItems.map((itdocs, index) => (
                                                   
                                                    <div className='discRow'>
                                                        {itdocs.documentType} :  {itdocs.schemeDocumentName} 
                                                        <a href={itdocs.schemeDocumentURL} target='_blank' title='Download File'> <i className="fa fa-file-pdf-o downPdfLk"></i></a>
                                                        <br />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    ))
                }
            </>
        )
    } else {
        <>

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12"> 
                            <h4 className="NoDataAvailableText"> No Data Available </h4>
                        </div>
                    </div>
                </div>
            </section>
        </>
    }
}

export default AllRunningSchemes