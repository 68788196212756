import React from 'react'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'

const MyOrderAlert = () => {

    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');

    const { data, error, isLoaded } = GetRequest(`/Orders/GetRecentPartialOrderDispatchDetails/${distID}`);

    if (error !== null) {
        return <Error data=" My Orders Alerts" />;
    }
    if (!isLoaded) {
        return <Loading />;
    }
 if(data.result !== '' && data.result.length !== 0) {
    return (
        <>
            <div className="card card-danger">
                <div className="card-header">
                    <h3 className="card-title">
                        <i className="fa fa-exclamation-triangle"></i>
                        My Order Alerts
                    </h3>
                </div>

                <div className="card-body">
                    <div className="row">
                        {
                            data.result && data.result.map((item, index) => (

                                <div className="col-md-6">
                                    <div className={`alert alert-${item.informationType} alert-warning`}    id={item.id}>
                                        <h5><i className="icon fa fa-ban"></i> Alert!</h5>
                                        {item.alertText}  <span className='vdtsLink' data-bs-toggle="collapse" data-bs-target={`#myOrdDets-${item.id}`}> View Details <i className="fa fa-arrow-circle-down"></i></span>
                                        <div id={`myOrdDets-${item.id}`} className='collapse myOrdDets'> 
                                            {
                                                item.notificationData && item.notificationData.map((items, index) => ( 
                                                    <label>{items}</label>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
} else {
    return (
      <> </>
     )
  }
}

export default MyOrderAlert