import React from 'react'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'

const Videos = () => {

    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');

    const { data, error, isLoaded } = GetRequest(`/Video/GetVideolist`);


    if (error !== null) {
        return <Error data="शेतीविषयक व्हिडीओ " />;
    }
    if (!isLoaded) {
        return <Loading />;
    }

    return (
        <>
            <section className="content">
                <div className="container-fluid">
                    <div className="card card-danger">
                        <div className="card-header"> शेतीविषयक व्हिडीओ</div>
                        <div className="card-body no-padding">
                            <div className="row">
                            {
                                    data.result.map((item, index) => (
                                        <div className="col-md-6" id={item.id}>
                                                <div className="card">
                                                <div className="card-header">  {item.title}</div>
                                                    <div className="card-body no-padding">
                                                    <iframe width="100%" height="315" src={item.url} title={item.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>

                                                    </div>
                                                   
                                                </div> 
                                            </div>
                                    ))
                                } 
                            </div>
                        </div>
                    </div>

                </div>
            </section>


        </>
    )
}

export default Videos