
import React from 'react' 
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'  
import LiveSugImg from '../Assets/img/whitegold1.png';
import newOrderImg from '../Assets/img/Icon/newordericon.png';
import newpaymentImg from '../Assets/img/Icon/newpaymenticon.jpg';
import { Link } from 'react-router-dom';

const RecommendationDashboard = () => {
  
  const distID = localStorage.getItem('distributorId');
  const boostID = localStorage.getItem('companyIdBooster');
  const parisID = localStorage.getItem('companyIdParis');

  const { data, error, isLoaded } = GetRequest("/Recommendation/GetRecentRecommandations");
  
  if (error !== null) {
    return <Error data=" " />;
  }
  if (!isLoaded) {
    return <Loading />;
  }
  

    return (
        <>
            <section className="content">
                <div className="container-fluid">
                
                    <div className="row">
                        <div className="col-md-12"> 

                        <div className="card card-primary">
          

          <div className="card-body no-padding collapse show"  id="collapse-no-head"  data-bs-parent="#accordion-no">
            <ul className="nav flex-column">
              <div id="accordion farmerLiveHomeUI">
                {
                  data.result && data.result.map(item => ( 
                    <div className="card card-liveSuggCol">
                      <div className="card-header"  data-bs-toggle="collapse" href={`#collapse${item.id}`}> 
                        
                        <label className='lblhead'> {item.title} </label> 
                           <i className="fa fa-plus  dp-right-mt5"></i>
                        
                      </div>
                      <div id={`collapse${item.id}`} className=" " data-bs-parent="#accordion">
                        <div className="card-body  no-padding">
                        <p className='pdetls'> 
                          <b>Problems :- </b>{item.fieldProblemTitle} 
                          <a   href={`whatsapp://send?text=${item.messageForSharing}`}  data-action="share/whatsapp/share"  className="botao-wpp-HomeIcon" target="_blank">
                                <i className="fa fa-whatsapp whatsappIconShare"></i>
                            </a> 
                            <br />
                          <b>Problem Type :- </b>{item.problemGroupTitle} <br />
                          <b>Suggestions :- </b>{item.suggestion}.<br />
                          </p> 
                            <h6 className='subTitle sinleIconDisplay'> Recommended Product  </h6>
                            {
                              item.recommendedProductLines && item.recommendedProductLines.map(items => (
                                <p className='pdetls'> 
                                     <b>Product Name :- </b> {items.productName}<br />
                                     <b>Dosage Information :- </b>  {items.dosageInformationPerPump}.<br />
                                     <b>Dosage Info Per Acre :- </b> {items.dosageInformationPerAcre}.<br />
                                     <b>Comments  :- </b> {items.comments}.<br />
                                     <div className='col-12 combineProductIcon'> 
                                        <i className="fa fa-plus PlusIcon"></i>
                                     </div>
                                    
                                </p>
                              ))
                            } 

                        </div>
                      </div>

                    </div>

                  ))
                }
              </div>
            </ul>
          </div>
           
        </div>


                           
 
                        </div>
                    </div>
                    
                     
                </div>
            </section>
        </>
    )
}

export default RecommendationDashboard