import React from 'react'
import PageTopHeadTitleBread from '../Components/PageTopHeadTitleBread'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error' 


const EventDashboard = () => {
  const distID = localStorage.getItem('distributorId');
  const boostID = localStorage.getItem('companyIdBooster');
  const parisID = localStorage.getItem('companyIdParis'); 

  const { data, error, isLoaded } = GetRequest(`/Event/GetListOfCampaignsEventsInDistributorArea/${distID}`); 

if (error !== null) {
  return <Error data=" " />;
}
if (!isLoaded) {
  return  <Loading />;
} 
  return (
    <>
      <PageTopHeadTitleBread title={"Events"} />
      <section className="content">
            <div className="container-fluid">  
                <div className="row"> 
                  <div className="card"> 
                     <div className="row card-body"> 
                      <div className="col-md-12">
       
                      <div id="accordion"> 
                      { 
                             data.result.map(item => (
                              <div className="card  ">
                                <div className="card-header bg-primary text-white">
                                  <a className="btn" data-bs-toggle="collapse" href={`#collapse${item.id}`}>
                                      {item.name}
                                  </a>
                                </div>
                                <div id={`collapse${item.id}`} className="collapse" data-bs-parent="#accordion">
                                  <div className="card-body">
                                         {item.details}
                                  </div>
                                </div>
                              </div>
                          ))
                        } 
                          </div> 
                        </div> 
                    </div>
                </div>
             </div>
            </div> 
        </section> 
    </>
  )
}

export default EventDashboard