import React from 'react'
import { useState } from 'react';
import GetRequest from '../Services/APIRequest'

const OrderShipToDropDown = () => {
    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis'); 
  
    const { data, error, isLoaded } = GetRequest(`/DistributorChannelPartner/GetDistributorChannelPartners/${distID}`);
      

  
    if (data.result != undefined) {
        return (
            <> 
                {
                    data.result.map((item, index) => (
                        <option id={item.id} value={item.id}> {item.name}</option>
                    ))
                } 
            </>
        )
    }
}

export default OrderShipToDropDown
