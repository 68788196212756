import React from 'react'
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import { GoBack } from '../Layouts/GoBack';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const BPUPaymentDetails = () => {
  const Id = useParams();
  const distID = localStorage.getItem('distributorId');
  const boostID = localStorage.getItem('companyIdBooster');
  const parisID = localStorage.getItem('companyIdParis');
  const [inputs, setInputs] = useState({});
  const notifySuccess = () => toast.success('Payment approved successfully!', {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored"
  });
  const notifyReject = () => toast.error('Payment rejected successfully!', {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored"
  });
  const { data, error, isLoaded } = GetRequest(`/payment/GetPaymentSummaryByPaymentId/${Id.Id}`);

  if (error !== null) {
    return <Error data=" " />;
  }
  if (!isLoaded) {
    return <Loading />;
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }))
  }

  const approvedPayment = (event) => {
    const formData = {};
    formData.paymentId = event.target.id;
    formData.receiptNumber = event.target.getAttribute('data-trans-id');
    if (event.target.attributes[1].value !== 'Reject') {
      formData.status = 'Approved';
    } else {
      formData.status = 'Rejected';
    }

    formData.comments = inputs.comments;
    formData.approvedByName = localStorage.getItem('distributorDisplayName');
    formData.approvedByTeamAppId = localStorage.getItem('userId');
    formData.approvedByUserId = localStorage.getItem('userId');

    axios.post("https://dpapi.boosterparis.in/api/Payment/ApprovePayment", formData, {
      headers: {
        "Cache-Control": "no-cache",
        "Access-Control-Allow-Origin": "*",
        'Authorization': localStorage.getItem("loginToken"),
      },
    })
      .then((response) => {
        if (response.data.isSuccess === true) {
          if (response.data.result.status !== "Rejected") {
            notifySuccess();
          }
          else {
            notifyReject();
          }
          document.getElementById('statuschangediv').style.display = 'none';
          document.getElementById('spanapprovalremarks').innerText = formData.comments;
        }

      }).catch((response) => {
        // if(response.response.status === 401){  
        //     window.location.replace("/Logout");
        //  }
        alert("Something went wrong while updating payment status");
        console.log(response.statusText);
      });
  }

  return (
    <>

      <div className="row yes-padd-15" >
        <div className="col-sm-12">
          <h5 className='text-blue'> Payments details</h5>
          <div className="row" id={data.result.paymentId}>
            <div className="col-sm-6">
              <p className='text-grey'><b className='text-grey-bold'>Amount : Rs {data.result.totalPayment}/-  </b></p>
              <p><b className='text-grey-bold'>Transaction#  : </b>{data.result.transactionId}</p>
              <p><b className='text-grey-bold'> Date  : </b> {data.result.paymentDateStr} </p>
              <p><b className='text-grey-bold'> Receipt  : </b> <a href> -{data.result.paymentReceiptURL}  </a></p>
            </div>
            <div className="col-sm-6">
              <p><b className='text-grey-bold'> Status  : </b> {data.result.statusDisplayText} </p>
              <p><b className='text-grey-bold'> To Bank  : </b> {data.result.toBankDetails} </p>
              <p><b className='text-grey-bold'>  Payment For: </b> {data.result.paymentForCompany} </p>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <p>   Remarks: <b>{data.result.comments}</b>  </p>
              </div>
              {data.result.approvedBy != null ?
                <div className="form-group">
                  <p>   Approval Remarks:  {data.result.approvalComments}   </p>
                  <p>   Approved by:{data.result.approvedBy} </p>
                </div>
                :
                <div className="form-group" >
                  <label for="Comments " className="form-label"><b>Approval Remarks</b></label>

                  <p id='spanapprovalremarks'> </p>

                  {data.result.statusChangeAllowed == true ?
                    <div id='statuschangediv'>
                      <textarea name='comments' className="form-control txtDetailsHt" placeholder="Enter Remarks  here ..."
                        onChange={handleChange} />
                      <br />
                      <div >
                        <button className='btn btn-success btn-sm' data-trans-id={data.result.transactionId} id={data.result.paymentId} onClick={approvedPayment}>Approve</button> &nbsp;
                        <button className='btn btn-danger btn-sm' data-text-id="Reject" data-trans-id={data.result.transactionId} id={data.result.paymentId} onClick={approvedPayment}>Reject</button>
                      </div>
                    </div>
                    : ''}

                </div>

              }

              {/* {data.result.approvalComments != null && data.result.approvalComments != '' ? 
   <div className="form-group">
   <p>   Approved by:{data.result.approvedBy} </p>
   </div>     :'' }    */}
            </div>
          </div>
          <table className='table table-striped table-bordered tbUIHd'>
            <caption style={{ captionSide: "top" }}  ><span className='text-grey-bold'>Payment Bifurcation:Product Categories  </span>    </caption>
            <thead>
              <tr>
                <th className='text-grey-bold '>
                  No.
                </th>
                <th className='text-grey-bold '>
                  Payment For                            </th>
                <th className='text-grey-bold '>
                  Amount                            </th>
              </tr>
            </thead>
            <tbody>
              {
                data.result.costCentreWisePaymentDistributionDetails && data.result.costCentreWisePaymentDistributionDetails.map((items, index) => (
                  <>
                    <tr>
                      <td>
                        {index + 1}
                      </td>
                      <td>
                        {items.costCentreDisplayName}
                      </td>
                      <td className='rsRight'>
                        {items.paidAmountAgainstCostCentre}
                      </td>
                    </tr>
                  </>
                ))
              }
            </tbody>
          </table>
          <table className='table table-striped table-bordered tbUIHd'>
            <caption style={{ captionSide: "top" }}  ><span className='text-grey-bold'> Payment Distribution:Schemes  </span>    </caption>
            <thead>
              <tr>
                <th className='text-grey-bold '>
                  No.
                </th>
                <th className='text-grey-bold '>
                  Scheme  Name
                </th>
                <th className='text-grey-bold '>
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {
                data.result.schemeDistributionDetails && data.result.schemeDistributionDetails.map((items, index) => (
                  <>
                    <tr>
                      <td>
                        {index + 1}
                      </td>
                      <td>
                        {items.schemName}
                      </td>
                      <td className='rsRight'>
                        {items.paidAmountForScheme}
                      </td>
                    </tr>
                  </>
                ))
              }
            </tbody>
          </table>

        </div>
      </div>
      <ToastContainer limit={1} />
    </>
  )
}

export default BPUPaymentDetails