import React from 'react'
import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <footer className="main-footer">
       
      
    </footer>
  )
}

export default Footer