import React from 'react'
import GetRequest from '../Services/APIRequest'
import { Link } from 'react-router-dom'

const NotificationDashboard = () => {

  const distID = localStorage.getItem('distributorId');
  const boostID = localStorage.getItem('companyIdBooster');
  const parisID = localStorage.getItem('companyIdParis');


  const { data, error, isLoaded } =  GetRequest(`/Notification/ViewAllNotifications/${distID}`);

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">See All Notifications </h3>
                </div>
                <div className="card-body nobornopad">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card noUI accLedUI ">
                        <div className="card-body tbscroll" id="accListUI">
                          <div id="accordion" >
                            <br />
                            {
                              data.result && data.result.map((items, index) => ( 
                                <Link to={`${items.alertLink}`} className="dropdown-item nthl">
                                <div className="alert alert-primary itemfiltered"  data-item={items.informationType} id={items.id}>
                                    <strong>Info !</strong> <br />  {items.alertText}<br />
                                    <Link to={`${items.alertLink}`} className="dropdown-item nthl">Show Details</Link> 
                                    
                                    <br /> 
                                    {/* <Link to={items.alertLink} className='btn btn-primary btn-sm flr'>Show Details</Link>  */}
                              </div> </Link> 
                              ))
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default NotificationDashboard