import React from "react";
import axios from 'axios';
import { jsPDF } from 'jspdf';

import { useState } from 'react';
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import { Link, Outlet } from 'react-router-dom'
import { useNavigate, useParams } from "react-router-dom";



const ViewMyChannelPartnerReport = () => {

    const [pdfdata, setPdfData] = useState(null);


    const downloadAllOrdersReport = (event) => {
        event.preventDefault();
        axios.get(`https://dpapi.boosterparis.in/api/Orders/DownloadAllOrdersReportForChannelPartner/220127`, {
            headers: {
                "Cache-Control": "no-cache",
                "Access-Control-Allow-Origin": "*",
                'Authorization': localStorage.getItem("loginToken"),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setPdfData(response.data);
                    generatePdf();
                }
            }).catch((response) => {
                console.log(response.statusText);
            });
    }
    const generatePdf = () => {
        const doc = new jsPDF();
        doc.text(`AllOrders - ${distID}`, 14, 12);
        doc.text(pdfdata, 10, 20);
        doc.save(`AllOrders-${distID}.pdf`);
    };

    const Id = useParams();
    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');
    const userId = localStorage.getItem('userId');
    const userType = localStorage.getItem('userType');
    const navigate = useNavigate();
    const [errorMsg, setErrorMsg] = useState("");

    const { data, error, isLoaded } = GetRequest(`/Orders/GetRecentOrdersForChannelPartner/${Id.Id}`);

    if (error !== null) {
        return <Error data="Orders" />;
    }
    if (!isLoaded) {
        return <Loading />;
    }

    if (data.result.length !== 0) {
        return (
            <>

                <section className="content">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-md-12">

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-success">
                                    <div className="card-header">
                                        <h3 className="card-title">Orders from   {data.result.channelPartnerName}  </h3>

                                    </div>

                                    <div className="card-body">
                                        <div className="row">
                                            <a href={`https://dpapi.boosterparis.in/api/Orders/DownloadAllOrdersReportForChannelPartner/${Id.Id}`}
                                                target="_blank">
                                                All Orders Report
                                            </a>
                                        </div>
                                        <div className="row">
                                            {
                                                data.result && data.result.orders.map((item, index) => (

                                                    <div className="col-md-4">
                                                        <div className="card card-primary">
                                                            <div className="card-header ">
                                                                <h5 className="card-title">   {item.orderNumber} </h5>
                                                            </div>
                                                            <div className="card-body">
                                                                <h6>Status : {item.status}</h6>
                                                                <p>Number Of Items : {item.numberOfItems}</p>
                                                                <p>Order Date : {item.orderPlacedDate}</p>
                                                                <p>Amount  : <b>{item.totalWithTax}</b></p>
                                                                <Link to={`/OrderDetails/${item.id}`} className='btn btn-primary btn-sm' id={item.orderNumber}>View Details</Link>
                                                                <p id={`showerror${item.id}`} className="hide">
                                                                    <span className="badge rounded-pill bg-danger">{errorMsg}</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            <Outlet></Outlet>
                                        </div>
                                    </div>

                                    <div className="card-footer">
                                        <h3 className="card-title">Channel Partner Details:   {data.result.channelPartnerDetails}  </h3>
                                    </div>
                                    <div className="card-footer">

                                        <a href={`https://dpapi.boosterparis.in/api/Orders/DownloadAllOrdersReportForChannelPartner/${Id.Id}`}
                                            target="_blank">
                                            All Orders Report
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </>
        )
    } else {
        return (
            <>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-danger">
                                    <div className="card-header">
                                        <h3 className="card-title">
                                            <i className="fa fa-exclamation-triangle"></i>
                                            Order Alerts
                                        </h3>
                                    </div>

                                    <div className="card-body">

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="card card-warning">
                                    <div className="card-header">
                                        <h3 className="card-title">My Orders   </h3>
                                    </div>

                                    <div className="card-body">
                                        <div className="row">
                                            <h4 className="NoDataAvailableText"> No Data Available </h4>
                                            <Outlet></Outlet>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </>
        )
    }
}

export default ViewMyChannelPartnerReport