import React from 'react'
import { useState, useRef } from 'react';
import logo from '../Assets/img/logo.png';
import profileIcon from '../Assets/img/du.jpg';
import { Link, NavLink } from 'react-router-dom';
import GetRequest from '../Services/APIRequest'
 
function sidebarOff() {
  if (window.innerWidth < 991) {
    document.querySelector('.boostapp').classList.remove('active');
  }
} 
 
const BPUSidebar = () => {

  const bpuID = localStorage.getItem('userId');
  const [selectFormVal, setSelectFormVal] = useState("");
  const { data  } = GetRequest(`/User/GetDistributorsAssignedToUser/${bpuID}`);

  const handleSelectFormChange = (event) => {
    setSelectFormVal(event.target.value);
    localStorage.setItem('distributorId', event.target.value);
    if(localStorage.getItem('distributorId') !== '') {
      document.getElementById('distPayOrdViewLink').classList.remove('hide')
    } else {
      document.getElementById('distPayOrdViewLink').classList.add('hide')
    }
}
   
  return (
    <> 
    <aside className="main-sidebar">
      <span   className="brand-link">  <img src={logo} width="60" alt="Logo" /> </span>

      <div className="sidebar">
        <div className="user-panel">
          <div className="image">
            <img src={profileIcon} className="Profimg" alt="User Icon" />
          </div>
          <div className="info">
            <Link to={'BPUProfile'}  className="d-block ellipseTextWrap"> <label className='profNameSidebarUser'>{localStorage.getItem('distributorDisplayName')} </label>  <i className="fa fa-angle-right faconProLink"></i>    </Link>
          </div>
        </div> 
        <nav className="sideNavbarItem"> 
         

          <h6 id="top"> </h6>
          <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

            <li className="nav-item" id='arrow-up-sidebar'>
              <NavLink activeclassname="active" to="/BPUDashboard/BPUDashContent" className="nav-link" onClick={sidebarOff}>
                <i className="nav-icon fa fa-dashboard"></i>
                <span> Dashboard </span>
              </NavLink>
            </li>
            <div className='selectDistPayOrd' >
              
              <label for="Select Distributor " className="form-label">
                Select Distributor                                           
            </label> 
            <select className="form-control"   id="selectDistForBPU" value={selectFormVal} onChange={handleSelectFormChange}>
                <option value=""> Select Distributor for New Order/ Payment</option>  
                {
                  data.result && data.result.map((item, index) => (
                    <option  value={item.id}> {item.distributorDisplayName}</option>
                  ))
                }
            </select>

          </div> 
          <div id='distPayOrdViewLink' className='hide'>
              <li className="nav-item">
                <NavLink activeclassname="active" to="/NewPayment/SubmitPayment" className="nav-link" onClick={sidebarOff}>
                  <i className="nav-icon fa fa-bank"></i>
                  <span>  New Payment    </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink activeclassname="active" to="/NewOrder" className="nav-link" onClick={sidebarOff}>
                  <i className="nav-icon fa fa-cart-plus"></i>
                  <span>   New Order   </span>
                </NavLink>
              </li>  
            </div>
          </ul>
         
          <h6 id="bottom"></h6>
        </nav>
        <a href="#bottom" className='arrow-down-sidebar hide'></a>
        <a href="#top" className='arrow-up-sidebar hide'></a> 
      </div> 
    </aside>
    
    
    </>
  )
}




export default BPUSidebar 