import React from 'react'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import { Link, Outlet } from 'react-router-dom'


const DistributorDashboard = () => {
    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');

    const { data, error, isLoaded } = GetRequest(`/Reports/GetSummaryReportForDistributorsOrDistributorsFromLedgerGroup/${distID}/distributor/none`);

    if (error !== null) {
        return <Error data=" Reports" />;
    }
    if (!isLoaded) {
        return <Loading />;
    }

    return (
        <>

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <h3 className="card-title">{data.result.entityName} </h3>
                        </div>
                        <div className="col-12">
                            <h3 className="card-title">Booster Closing Balance:{data.result.closingBalanceBooster} </h3>
                        </div>
                        <div className="col-12">
                            <h3 className="card-title">Paris Closing Balance:{data.result.closingBalanceParis} </h3>
                        </div>
                        <div className="col-12">
                            <h3 className="card-title">Last Inventory Update:{data.result.inventoryLastUpdateDateStr} </h3>
                        </div>
                    </div>

                    <div id="accordion">
                        <div className='row'>
                            <div className="col-12 col-sm-12 col-md-12  itemfiltered" data-item='divCostCentreWisePayment' id='divCostCentreWisePayment'>
                                <div className="card card-warning">
                                    <div className="card-header">
                                        <div className="row">
                                            <div className="col-sm-12" data-bs-toggle="collapse" href={`#collapsedivCostCentreWisePayment`}>
                                                <h3 className="card-title">Cost Centre Wise Collection </h3>
                                                <a className="btn dp-right-mt5">
                                                    <i className="fa fa-angle-double-down"></i>
                                                    {/* <i className="fa fa-angle-double-up"></i> */}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div id={`collapsedivCostCentreWisePayment`} className="collapse" data-bs-parent="#accordion">
                                        <div className="card-body">
                                            <table className='table'>
                                                <tbody>
                                                    {
                                                        data.result && data.result.costCentreWisePayments && data.result.costCentreWisePayments.length > 0 ? (
                                                            data.result.costCentreWisePayments.map((item, index) => (

                                                                <tr key={index}>
                                                                    <td className='w50'>{item.title}</td>
                                                                    <td className='dtSmall'>{item.countValue}</td>
                                                                </tr>

                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="2">No data available or all items have zero count.</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className="col-12 col-sm-12 col-md-12  itemfiltered" data-item='productCategoriWiseOrderVaule' id='productCategoriWiseOrderVaule'>
                                <div className="card card-warning">
                                    <div className="card-header">
                                        <div className="row">
                                            <div className="col-sm-12" data-bs-toggle="collapse" href={`#collapseproductCategoriWiseOrderVaule`}>
                                                <h3 className="card-title">Product Categorywise Order Amount</h3>
                                                <a className="btn dp-right-mt5">
                                                    <i className="fa fa-angle-double-down"></i>
                                                    {/* <i className="fa fa-angle-double-up"></i> */}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div id={`collapseproductCategoriWiseOrderVaule`} className="collapse" data-bs-parent="#accordion">
                                        <div className="card-body">
                                            <table className='table'>
                                                <tbody>
                                                    {
                                                        data.result && data.result.productCategoriWiseOrderVaule && data.result.productCategoriWiseOrderVaule.length > 0 ? (
                                                            data.result.productCategoriWiseOrderVaule.map((item, index) => (

                                                                <tr key={index}>
                                                                    <td className='w50'>{item.title}</td>
                                                                    <td className='dtSmall'>{item.countValue}</td>
                                                                </tr>

                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="2">No data available or all items have zero count.</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className="col-12 col-sm-12 col-md-12  itemfiltered" data-item='productLineWiseOrderVaule' id='productLineWiseOrderVaule'>
                                <div className="card card-warning">
                                    <div className="card-header">
                                        <div className="row">
                                            <div className="col-sm-12" data-bs-toggle="collapse" href={`#collapseproductLineWiseOrderVaule`}>
                                                <h3 className="card-title">Product Line wise Order Amount</h3>
                                                <a className="btn dp-right-mt5">
                                                    <i className="fa fa-angle-double-down"></i>
                                                    {/* <i className="fa fa-angle-double-up"></i> */}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div id={`collapseproductLineWiseOrderVaule`} className="collapse" data-bs-parent="#accordion">
                                        <div className="card-body">
                                            <table className='table'>
                                                <tbody>
                                                    {
                                                        data.result && data.result.productLineWiseOrderVaule && data.result.productLineWiseOrderVaule.length > 0 ? (
                                                            data.result.productLineWiseOrderVaule.map((item, index) => (

                                                                <tr key={index}>
                                                                    <td className='w50'>{item.title}</td>
                                                                    <td className='dtSmall'>{item.countValue}</td>
                                                                </tr>

                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="2">No data available or all items have zero count.</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='row'>
                            <div className="col-12 col-sm-12 col-md-12  itemfiltered" data-item='productLinesNotOrdered' id='productLinesNotOrdered'>
                                <div className="card card-warning">
                                    <div className="card-header">
                                        <div className="row">
                                            <div className="col-sm-12" data-bs-toggle="collapse" href={`#collapseproductLinesNotOrdered`}>
                                                <h3 className="card-title">ProductLines:Not Ordered</h3>
                                                <a className="btn dp-right-mt5">
                                                    <i className="fa fa-angle-double-down"></i>
                                                    {/* <i className="fa fa-angle-double-up"></i> */}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div id={`collapseproductLinesNotOrdered`} className="collapse" data-bs-parent="#accordion">
                                        <div className="card-body">
                                            <div className="card-body">
                                                <div className="row">
                                                    {
                                                        data.result && data.result.productLinesNotOrdered && data.result.productLinesNotOrdered.length > 0 ? (
                                                            data.result.productLinesNotOrdered.map((item, index) => (
                                                                <div class='col-6'>
                                                                    {item.title}
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <div col-12 col-sm-12 col-md-12>
                                                                No data available.
                                                            </div>)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='row'>
                            <div className="col-12 col-sm-12 col-md-12  itemfiltered" data-item='productItemWiseOrderVaule' id='productItemWiseOrderVaule'>
                                <div className="card card-warning">
                                    <div className="card-header">
                                        <div className="row">
                                            <div className="col-sm-12" data-bs-toggle="collapse" href={`#collapseproductItemWiseOrderVaule`}>
                                                <h3 className="card-title">Product Item wise Order Amount</h3>
                                                <a className="btn dp-right-mt5">
                                                    <i className="fa fa-angle-double-down"></i>
                                                    {/* <i className="fa fa-angle-double-up"></i> */}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div id={`collapseproductItemWiseOrderVaule`} className="collapse" data-bs-parent="#accordion">
                                        <div className="card-body">
                                            <table className='table'>
                                                <tbody>
                                                    {
                                                        data.result && data.result.productItemWiseOrderVaule && data.result.productItemWiseOrderVaule.length > 0 ? (
                                                            data.result.productItemWiseOrderVaule.map((item, index) => (

                                                                <tr key={index}>
                                                                    <td className='w50'>{item.title}</td>
                                                                    <td className='dtSmall'>{item.countValue}</td>
                                                                </tr>

                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="2">No data available or all items have zero count.</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className="col-12 col-sm-12 col-md-12  itemfiltered" data-item='productItemsOrderedQuantityInPrimaryUnit' id='productItemsOrderedQuantityInPrimaryUnit'>
                                <div className="card card-warning">
                                    <div className="card-header">
                                        <div className="row">
                                            <div className="col-sm-12" data-bs-toggle="collapse" href={`#collapseproductItemsOrderedQuantityInPrimaryUnit`}>
                                                <h3 className="card-title">Product Items Ordered Qty in Primary Unit</h3>
                                                <a className="btn dp-right-mt5">
                                                    <i className="fa fa-angle-double-down"></i>
                                                    {/* <i className="fa fa-angle-double-up"></i> */}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div id={`collapseproductItemsOrderedQuantityInPrimaryUnit`} className="collapse" data-bs-parent="#accordion">
                                        <div className="card-body">
                                            <table className='table'>
                                                <tbody>
                                                    {
                                                        data.result && data.result.productItemsOrderedQuantityInPrimaryUnit && data.result.productItemsOrderedQuantityInPrimaryUnit.length > 0 ? (
                                                            data.result.productItemsOrderedQuantityInPrimaryUnit.map((item, index) => (

                                                                <tr key={index}>
                                                                    <td className='w50'>{item.title}</td>
                                                                    <td className='dtSmall'>{item.countValue}</td>
                                                                </tr>

                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="2">No data available or all items have zero count.</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className="col-12 col-sm-12 col-md-12  itemfiltered" data-item='productItemsOrderedUnitsInNumbers' id='productItemsOrderedUnitsInNumbers'>
                                <div className="card card-warning">
                                    <div className="card-header">
                                        <div className="row">
                                            <div className="col-sm-12" data-bs-toggle="collapse" href={`#collapseproductItemsOrderedUnitsInNumbers`}>
                                                <h3 className="card-title">Product Items - Ordered Units</h3>
                                                <a className="btn dp-right-mt5">
                                                    <i className="fa fa-angle-double-down"></i>
                                                    {/* <i className="fa fa-angle-double-up"></i> */}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div id={`collapseproductItemsOrderedUnitsInNumbers`} className="collapse" data-bs-parent="#accordion">
                                        <div className="card-body">
                                            <table className='table'>
                                                <tbody>
                                                    {
                                                        data.result && data.result.productItemsOrderedUnitsInNumbers && data.result.productItemsOrderedUnitsInNumbers.length > 0 ? (
                                                            data.result.productItemsOrderedUnitsInNumbers.map((item, index) => (

                                                                <tr key={index}>
                                                                    <td className='w50'>{item.title}</td>
                                                                    <td className='dtSmall'>{item.countValue}</td>
                                                                </tr>

                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="2">No data available or all items have zero count.</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className="col-12 col-sm-12 col-md-12  itemfiltered" data-item='productItemsNotOrdered' id='productItemsNotOrdered'>
                                <div className="card card-warning">
                                    <div className="card-header">
                                        <div className="row">
                                            <div className="col-sm-12" data-bs-toggle="collapse" href={`#collapseproductItemsNotOrdered`}>
                                                <h3 className="card-title">Product Items: Not Ordered</h3>
                                                <a className="btn dp-right-mt5">
                                                    <i className="fa fa-angle-double-down"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div id={`collapseproductItemsNotOrdered`} className="collapse" data-bs-parent="#accordion">
                                        <div className="card-body">
                                            <div className="row">
                                                {
                                                    data.result && data.result.productItemsNotOrdered && data.result.productItemsNotOrdered.length > 0 ? (
                                                        data.result.productItemsNotOrdered.map((item, index) => (
                                                            <div class='col-6'>
                                                                {item.title}
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div col-12 col-sm-12 col-md-12>
                                                            No data available.
                                                        </div>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-12 col-sm-12 col-md-12  itemfiltered" data-item='schemeWisePayments' id='schemeWisePayments'>
                                <div className="card card-warning">
                                    <div className="card-header">
                                        <div className="row">
                                            <div className="col-sm-12" data-bs-toggle="collapse" href={`#collapseschemeWisePayments`}>
                                                <h3 className="card-title">Scheme Payments</h3>
                                                <a className="btn dp-right-mt5">
                                                    <i className="fa fa-angle-double-down"></i>
                                                    {/* <i className="fa fa-angle-double-up"></i> */}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div id={`collapseschemeWisePayments`} className="collapse" data-bs-parent="#accordion">
                                        <div className="card-body">
                                            <table className='table'>
                                                <tbody>
                                                    {
                                                        data.result && data.result.schemeWisePayments && data.result.schemeWisePayments.length > 0 ? (
                                                            data.result.schemeWisePayments.map((item, index) => (

                                                                <tr key={index}>
                                                                    <td className='w50'>{item.title}</td>
                                                                    <td className='dtSmall'>{item.countValue}</td>
                                                                </tr>

                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="2">No data available or all items have zero count.</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}



export default DistributorDashboard