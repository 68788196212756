import React from 'react'
import { Outlet } from 'react-router-dom'

const MyPaymentsDashboard = () => {
  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="card card-success">
            <div className="card-header"> 
              <h3 className="card-title">My Payments   </h3>
            </div>
            <div className="card-body no-padding">  
                <Outlet></Outlet> 
            </div>
          </div> 
        </div>
      </section>
    </>
  )
}

export default MyPaymentsDashboard