import React from 'react'
import {  BrowserRouter, Routes, Route } from "react-router-dom";
import Home from '../Pages/Home'
import Login from '../Layouts/Login';
import Dashboard from '../Layouts/Dashboard';   
import AccountDashboard from '../AccountSummary/AccountDashboard'; 
import PaymentDashboard from '../NewPayment/PaymentDashboard';
import InventoryDashboard from '../Inventory/InventoryDashboard'; 
import FarmerAreaDashboard from '../FarmerArea/FarmerAreaDashboard';
import EventDashboard from '../Events/EventDashboard';
import CropDashboard from '../Crops/CropDashboard'; 
import MyChannelDashboard from '../MyChannel/MyChannelDashboard';
import MyOrderDashboard from '../MyOrder/MyOrderDashboard';
import NewOrderDashboard from '../NewOrder/NewOrderDashboard';
import CartDashboard from '../OrderCart/CartDashboard'; 
import ProductsDashboard from '../Products/ProductsDashboard';
import RaiseQueryDashboard from '../RaiseQuery/RaiseQueryDashboard';
import RecommendationDashboard from '../Recommendation/RecommendationDashboard';
import SchemesDashboard from '../Schemes/SchemesDashboard';
import SpreadWordDashboard from '../SpreadWord/SpreadWordDashboard';
import SubmitPaymentForm from '../NewPayment/SubmitPaymentForm';
import PaymentDistributionForm from '../NewPayment/PaymentDistributionForm';
import PaymentSummaryDescription from '../NewPayment/PaymentSummaryDescription';
import OrderSummary from '../NewOrder/OrderSummary';  
import CreateQuery from '../RaiseQuery/CreateQuery';
import OrderDetails from '../MyOrder/OrderDetails';
import AddChannelPartner from '../MyChannel/AddChannelPartner';
import PartnerProfile from '../MyChannel/PartnerProfile';
import CropWiseReccomandations from '../MoreInformation/CropWiseReccomandations'
import DelearFinance from '../MoreInformation/DelearFinance'
import ExpiringStock from '../MoreInformation/ExpiringStock'
import Geetings from '../MoreInformation/Geetings'
import Tips from '../MoreInformation/Tips'
import MoreInformationDashboard from '../MoreInformation/MoreInformationDashboard'
import OurTeamContacts from '../MoreInformation/OurTeamContacts'
import Yashogatha from '../MoreInformation/Yashogatha'
import Videos from '../MoreInformation/Videos'
import WorkGuidence from '../MoreInformation/WorkGuidence'
import WorkGuidenceEvents from '../MoreInformation/WorkGuidenceEvents' 
import Articals from '../MoreInformation/Articals'
import ProductNeverOrder from '../MoreInformation/ProductNeverOrder'
import ShareObservations from '../MoreInformation/ShareObservations'
import ChannelObservationList from '../MoreInformation/ChannelObservationList'
import Profitability from '../MoreInformation/Profitability'
import ViewMyChannelPartnerReport from '../MyChannel/ViewMyChannelPartnerReport'
import SchemesDetails from '../Schemes/SchemesDetails'
import OrderTransactionDetails from '../NewOrder/OrderTransactionDetails';
import ProfileDashboard from '../Profile/ProfileDashboard';
import NotificationDashboard from '../Notifications/NotificationDashboard';
import PaymentDistributionsEdit from '../MyPayments/PaymentDistributionsEdit';
import PaymentSummaryDetails from '../MyPayments/PaymentSummaryDetails';
import MyPaymentsDashboard from '../MyPayments/MyPaymentsDashboard';
import SubmitPaymentEdit from '../MyPayments/SubmitPaymentEdit';
import MyPaymentsList from '../MyPayments/MyPaymentsList';
import NativeLoginSuccessful from '../Layouts/NativeLoginSuccessful';
import Logout from '../Layouts/Logout';
import BPUPaymentList from '../BPUser/BPUPaymentList';
import BPUOrderList from '../BPUser/BPUOrderList';
import BPUDashboard from '../BPUser/BPUDashboard';
import BPUProfile from '../BPUser/BPUProfile';
import BPUDashContent from '../BPUser/BPUDashContent'; 
import BPUPaymentDetails from '../BPUser/BPUPaymentDetails';
import BPUOrderDetails from '../BPUser/BPUOrderDetails';
import BPDemoRoute from '../BPUser/BPDemoRoute';
import DistributorReports from '../BPUser/DistributorReports';



const initialHistoryLength = window.history.length;

window.addEventListener('popstate', function(event) {
    if (window.history.length < initialHistoryLength) {
      window.history.back(-1);
    }
});


const Navigation = () => {
  return (
    <BrowserRouter>
      <Routes>   
       <Route path="/BPDemoRoute"   element={ <BPDemoRoute /> } />    
          <Route path="/Login"   element={ <Login /> } />    
          <Route path='/NativeLoginSuccessful' element={ <NativeLoginSuccessful /> } />
          <Route path='/Logout' element={ <Logout /> } />
          <Route path='/BPUDashboard' element={<BPUDashboard /> } >
            <Route path='PaymentList/:Id' element={ <BPUPaymentList /> } > </Route>  
            <Route path='OrderList/:Id' element={ <BPUOrderList /> } > </Route>  
            <Route path='BPUPaymentDetails/:Id' element={ <BPUPaymentDetails /> }></Route>
            <Route path='BPUOrderDetails/:Id' element={ <BPUOrderDetails /> }></Route>            
            <Route path='BPUProfile' element={ <BPUProfile /> } > </Route> 
            <Route path='BPUDashContent' element={ <BPUDashContent /> } > </Route>   
            <Route path='NewPayment' element={ <PaymentDashboard /> }>
                <Route path='SubmitPayment' element={<SubmitPaymentForm />} />
                <Route path='PaymentDistribution' element={<PaymentDistributionForm />} />
                <Route path='PaymentSummary' element={<PaymentSummaryDescription />} />
            </Route>
            <Route path="DistributorReports" element={ <DistributorReports /> } />
          </Route>
          <Route path="/" element={ <Dashboard /> } >
            <Route path="/" element={ <Home /> } >  </Route>   
            <Route path='AccountSummary' element={ <AccountDashboard /> } > </Route>  
            <Route path='NewPayment' element={ <PaymentDashboard /> }>
                <Route path='SubmitPayment' element={<SubmitPaymentForm />} />
                <Route path='PaymentDistribution' element={<PaymentDistributionForm />} />
                <Route path='PaymentSummary' element={<PaymentSummaryDescription />} />
            </Route>
            <Route path='Inventory' element={ <InventoryDashboard /> }> </Route> 
            <Route path='FarmerArea' element={ <FarmerAreaDashboard /> }> </Route>
            <Route path='Events' element={ <EventDashboard /> }> </Route>
            <Route path='Crops' element={ <CropDashboard /> }> </Route> 
            <Route path='MyChannel' element={ <MyChannelDashboard /> }> </Route>
            <Route path='MyOrder' element={ <MyOrderDashboard /> }></Route> 
            <Route path='OrderDetails/:Id' element={ <OrderDetails /> }> </Route>
            <Route path='NewOrder' element={ <NewOrderDashboard /> }> 
              <Route path='OrderSummary' element={ <OrderSummary /> }> </Route>
            </Route>
            <Route path='Carts' element={ <CartDashboard /> }> </Route> 
            <Route path='Products' element={ <ProductsDashboard /> }> </Route> 
            <Route path='PartnerProfile' element={ <PartnerProfile /> }> </Route>
            <Route path='AddChannelPartner' element={ <AddChannelPartner /> }> </Route> 
            <Route path='RaiseQuery' element={ <RaiseQueryDashboard /> }> </Route> 
            <Route path='CreateQuery' element={ <CreateQuery /> }> </Route>
            <Route path='Recommendation' element={ <RecommendationDashboard /> }> </Route>
            <Route path='Schemes' element={ <SchemesDashboard /> }> </Route>
            <Route path='SpreadTheWord' element={ <SpreadWordDashboard /> }> </Route>
            <Route path='Articles' element={ <Articals /> } > </Route>
            <Route path='CropWiseReccomandations' element={ <CropWiseReccomandations /> } > </Route>
            <Route path='DelearFinance' element={<DelearFinance /> } > </Route>
            <Route path='ExpiringStock' element={ <ExpiringStock />} > </Route>
            <Route path='Greetings' element={ <Geetings /> } > </Route>
            <Route path='Tips' element={ <Tips />} > </Route>
            <Route path='MoreInformation' element={<MoreInformationDashboard /> } > </Route>
            <Route path='OurTeamContacts' element={ <OurTeamContacts />} > </Route>
            <Route path='ProductNeverOrder' element={ <ProductNeverOrder />} > </Route>
            <Route path='ShareObservations' element={ <ShareObservations /> } > </Route>
            <Route path='ChannelObservationList' element={ <ChannelObservationList /> } > </Route>
            <Route path='Yashogatha' element={ <Yashogatha />} > </Route>
            <Route path='Videos' element={<Videos /> } > </Route>
            <Route path='WorkGuidence' element={<WorkGuidence />} > </Route>
            <Route path='Profitability' element={<Profitability />} > </Route>
            <Route path='WorkGuidenceEvents' element={ <WorkGuidenceEvents />} > </Route>
            <Route path='ViewMyChannelPartnerReport/:Id' element={<ViewMyChannelPartnerReport /> }></Route>
            <Route path='SchemesDetails/:Id' element={<SchemesDetails />} ></Route>
            <Route path='OrderTransactionDetails' element={<OrderTransactionDetails />} ></Route>
            <Route path='ProfileDashboard' element={<ProfileDashboard /> }> </Route>
            <Route path='NotificationDashboard' element={ <NotificationDashboard /> } ></Route>        
            <Route path='MyPayments' element={ <MyPaymentsDashboard /> } >
              <Route path='PaymentDetails/:Id' element={ <PaymentSummaryDetails /> } ></Route>
              <Route path='PaymentDistributionsEdit' element={ <PaymentDistributionsEdit /> } ></Route>
              <Route path='MyPaymentsList' element={ <MyPaymentsList /> } ></Route>
              <Route path='PaymentEdit/:Id' element={ <SubmitPaymentEdit /> } ></Route>
            </Route>
          </Route>   
      </Routes>    
    </BrowserRouter>
  )
}

export default Navigation