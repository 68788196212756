
import React from 'react'
import { Link, useParams } from 'react-router-dom';
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import { GoBack } from '../Layouts/GoBack';



const PaymentSummaryDetails = () => {
  const Id = useParams();
  const distID = localStorage.getItem('distributorId');
  const boostID = localStorage.getItem('companyIdBooster');
  const parisID = localStorage.getItem('companyIdParis');

  const { data, error, isLoaded } = GetRequest(`/payment/GetPaymentSummaryByPaymentId/${Id.Id}`);
  if (error !== null) {
    return <Error data=" " />;
  }
  if (!isLoaded) {
    return <Loading />;
  }

  return (
    <>
      <div className="row yes-padd-15" >
        <div className="col-sm-12">
          <Link to="/" className='btn btn-success fright'>Home</Link>
          <Link to="/MyPayments/MyPaymentsList" className='btn btn-success fright' style={{ marginRight: '15px' }} >Back to My Payments</Link>

        </div>
      </div>

      <div className="row yes-padd-15" >
        <div className="col-sm-12">
          <h5 className='text-blue'> Payments details</h5>

          <div className="row" id={data.result.paymentId}>
            <div className="col-sm-6">
              <p className='text-grey'><b className='text-grey-bold'>Total Payment : Rs {data.result.totalPayment}/-  </b></p>
              <p className='text-grey'><b className='text-grey-bold'>Status: </b> {data.result.statusDisplayText} </p>
              <p className='text-grey'><b className='text-grey-bold'>Account Receipt# : </b> {data.result.voucherNumber} </p>

              {/* <p><b className='text-grey-bold'>Transaction By : </b>{data.result.transactionBy} </p> */}
              <p><b className='text-grey-bold'>Transaction#  : </b>{data.result.transactionId}</p>
              <p><b className='text-grey-bold'> Receipt  : </b>

                -  {data.result.paymentReceiptURL ? <a href={`/Payment/downloadFile?fileName=${data.result.paymentReceiptURL}`} download>Download</a> : "-"}

              </p>
            </div>
            <div className="col-sm-6">
              <p><b className='text-grey-bold'>Date  : </b> {data.result.paymentDateStr} </p>
              <p className='text-grey'><b className='text-grey-bold'>Portal Voucher#: </b> {data.result.displayVoucherNumber} </p>
              {/* <p><b className='text-grey-bold'> From Bank Details : </b> {data.result.fromBankDetails} </p> */}
              <p><b className='text-grey-bold'> To Bank : </b> {data.result.toBankDetails} </p>
              {/* <p><b className='text-grey-bold'>  Distributor GSTIN : </b> {data.result.distributorGSTIN} </p> */}
              <p><b className='text-grey-bold'>  Payment For : </b> {data.result.paymentForCompany} </p>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <p>   Remarks: <b>{data.result.comments}</b>  </p>
              </div>
              {data.result.approvedBy != null ?
                <div className="form-group">
                  <p>   Approval Remarks: <b>{data.result.approvalComments}</b>  </p>
                </div>
                :
                ''
              }

              {data.result.approvalComments != null && data.result.approvalComments != '' ?
                <div className="form-group">
                  <p>   Approved by: <b>{data.result.approvalComments}</b>  </p>
                </div> : ''}
            </div>
          </div>


          <table className='table table-striped table-bordered tbUIHd'>
            <caption style={{ captionSide: "top" }}  ><span className='text-grey-bold'> Schemes Distribution Details  </span>    </caption>
            <thead>
              <tr>
                <th className='text-grey-bold '>
                  No.
                </th>
                <th className='text-grey-bold '>
                  Scheme
                </th>
                <th className='text-grey-bold '>
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {
                data.result.schemeDistributionDetails && data.result.schemeDistributionDetails.map((items, index) => (
                  <>
                    <tr>
                      <td>
                        {index + 1}
                      </td>
                      <td>
                        {items.schemName}
                      </td>
                      <td className='rsRight'>
                        {items.paidAmountForScheme}
                      </td>
                    </tr>
                  </>
                ))
              }
            </tbody>
          </table>
          <table className='table table-striped table-bordered tbUIHd'>
            <caption style={{ captionSide: "top" }}  ><span className='text-grey-bold'> Cost Centre Wise Payment Distribution  Details  </span>    </caption>
            <thead>
              <tr>
                <th className='text-grey-bold '>
                  No.
                </th>
                <th className='text-grey-bold '>
                  Cost Centre
                </th>
                <th className='text-grey-bold '>
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {
                data.result.costCentreWisePaymentDistributionDetails && data.result.costCentreWisePaymentDistributionDetails.map((items, index) => (
                  <>
                    <tr>
                      <td>
                        {index + 1}
                      </td>
                      <td>
                        {items.costCentreDisplayName}
                      </td>
                      <td className='rsRight'>
                        {items.paidAmountAgainstCostCentre}
                      </td>
                    </tr>
                  </>
                ))
              }
            </tbody>
          </table>

        </div>
      </div>
      <GoBack linkdata="/MyPayments/MyPaymentsList" />
    </>
  )
}

export default PaymentSummaryDetails