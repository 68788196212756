import React from 'react' 
import AllRunningSchemes from './AllRunningSchemes'
import ExpireSoonSchemes from './ExpireSoonSchemes'

const SchemesDashboard = () => {

   
  return (
    <>  
      <div className='row'>
        <div className='col-slg-12'>
            <ExpireSoonSchemes />
        </div>
      </div>
      <div className='row'>
        <div className='col-slg-12'>
            
        </div>
      </div>
    </>
  )
}

export default SchemesDashboard