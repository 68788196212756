import React, { useState, useEffect } from 'react'
import axios from 'axios';
import '../Assets/css/login.css';
import logo from '../Assets/img/logo.png';
import { useNavigate, Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/ReactToastify.css"


const Login = () => {

    const notify = () => toast.error('Please Enter Valid Credentials !', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    });
    const notifySuccess = () => toast.success('Please enter OTP recived on your Mobile !', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    });

    const [loginDetails, setLoginDetails] = useState({});

    const navigate = useNavigate();

    const handleChangeVal = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setLoginDetails(values => ({ ...values, [name]: value }));
    }

    const handleSubmitDetails = (event) => {
        event.preventDefault();
        loginDetails.otp = ''

        axios.post("https://dpapi.boosterparis.in/api/Login/RequestOTPForLogin", loginDetails, {
            headers: {
                "Cache-Control": "no-cache",
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then((response) => {
                if (response.data.result.otpSentToUser === true) {

                    document.getElementById("otpInputField").classList.remove('hide')
                    document.getElementById("mobileNumField").classList.add('hide')
                    document.getElementById("verifyOtpSubmit").classList.remove('hide')
                    document.getElementById("requestOtpSubmit").classList.add('hide')
                    notifySuccess();
                    //navigate('/');
                }
                else {
                    notify();
                }
            }).catch((response) => {
                console.log(response.statusText);
                notify();
            });

    }
    const handleSubmitDetailsOtp = (event) => {
        event.preventDefault();
        axios.post("https://dpapi.boosterparis.in/api/Login/VerifyOTPForLogin", loginDetails, {
            headers: {
                "Cache-Control": "no-cache",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((response) => {
                if (response.data.result.isLogInSuccessful === true) {
                    if (response.data.result.loginURL !== '') {
                        if (response.data.result.webLoginURL !== '') {
                            const loginTk = response.data.result.jwtTokenDTO.accesstoken;
                            navigate(response.data.result.webLoginURL);
                            window.location.replace(response.data.result.webLoginURL);
                        } else {
                            const loginTk = response.data.result.jwtTokenDTO.accesstoken;
                            navigate(response.data.result.loginURL);
                            window.location.replace(response.data.result.loginURL);
                        }

                    } else {
                        const loginTk = response.data.result.jwtTokenDTO.accesstoken;
                        localStorage.setItem('loginToken', loginTk);
                        localStorage.setItem("distributorDisplayName", response.data.result.firstName);
                        localStorage.setItem("distributorId", response.data.result.distributorId);
                        localStorage.setItem("userType", response.data.result.userType);
                        localStorage.setItem("companyIdBooster", 1);
                        localStorage.setItem("companyIdParis", 2);
                        navigate('/');
                        window.location.replace("/");
                    }

                } else {
                    notify();
                }
            }).catch((response) => {
                console.log(response.statusText);
                notify();
            });

    }

    return (

        <section className="body">
            <div className="container">
                <div className="login-box drop-in">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="logo">
                                <img src={logo} alt="Booster Paris" width={120} />
                            </div>
                            <h3 className="loginTitle">LOGIN</h3>
                            <form className="login-form" onSubmit={handleSubmitDetails} noValidate>
                                <div className="form-group" id="mobileNumField">
                                    <input type="text" name='mobileNumber' className="form-control"
                                        placeholder="Mobile Number / GST Number" value={loginDetails.mobileNumber || ""}
                                        onChange={handleChangeVal} id="mobileNumberLogin" required />
                                </div>

                                <div className="form-group"  >
                                    <input type="submit" className="btn btn-primary btn-block" id='requestOtpSubmit' value="Request OTP for LOGIN" />
                                </div>
                            </form>
                            <form className="login-form" onSubmit={handleSubmitDetailsOtp} noValidate>

                                <div className="form-group hide" id='otpInputField'>
                                    <input type="number" name='otp' className="form-control"
                                        placeholder="Enter Mobile OTP" value={loginDetails.otp || ""} required pattern="^(([0-9]*)|(([0-9]*)\.([0-9]*)))$"
                                        onChange={handleChangeVal} />

                                </div>
                                <div className="form-group"  >
                                    <input type="submit" className="btn btn-success btn-block hide" id='verifyOtpSubmit' value="Verify OTP for LOGIN" />
                                </div>
                            </form>


                            <ToastContainer />
                        </div>
                        <div className="col-sm-6 hide-on-mobile">
                            <div id="demo" className="carousel slide" data-ride="carousel">

                                <ul className="carousel-indicators">
                                    <li data-target="#demo" data-slide-to="0" className="active"></li>
                                    <li data-target="#demo" data-slide-to="1"></li>
                                </ul>

                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <div className="slider-feature-card">
                                            <img src="https://boosterparis.com/images/BOOSTERGROUP%20(2).jpg" alt="Booster Paris" />
                                            <h3>Welcome to Booster Plant Genetics Pvt. Ltd.</h3>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="slider-feature-card">
                                            <img src="https://boosterparis.com/images/BOOSTERGROUP%20(2).jpg" alt="Booster Paris" />
                                            <h3>Welcome to Booster Plant Genetics Pvt. Ltd.</h3>
                                        </div>
                                    </div>
                                </div>
                                <a className="carousel-control-prev" href="#demo" data-slide="prev">
                                    <span className="carousel-control-prev-icon"></span>
                                </a>
                                <a className="carousel-control-next" href="#demo" data-slide="next">
                                    <span className="carousel-control-next-icon"></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Login