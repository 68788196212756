import { useState, useEffect } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";

//const BASE_URL = process.env.REACT_APP_API;
//const BASE_URL = 'https:/-/erelatevmdev.centralindia.cloudapp.azure.com/api';
//const BASE_URL = 'https:/-/erelatevmdev.centralindia.cloudapp.azure.com/api';

const BASE_URL = 'https://dpapi.boosterparis.in/api';


const GetRequest = (APIName) => {

  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(BASE_URL + APIName, {
          headers: {
            "Cache-Control": "no-cache",
            "Access-Control-Allow-Origin": "*",
            'Authorization': localStorage.getItem("loginToken"),
          },
        })
        .then(response => {
          setIsLoaded(true);
          setData(response.data);

        })
        .catch(error => {
          if (error.code !== "ERR_NETWORK") {
            if (error.response.status == 401) {
              <Navigate to="/Logout" />;
              window.location.replace("/Logout");
            }
          } else {
            setError(error);
          }
          setError(error);
        });
    };

    fetchData();

  }, [BASE_URL + APIName]);

  return { error, isLoaded, data };
};

export default GetRequest;

export const BASE_URL1 = BASE_URL;