import React from 'react'
import NotificationHeader from '../Notifications/NotificationHeader';

function showHideSidebar() {
  document.querySelector('.boostapp').classList.toggle('active');
}

// window.addEventListener('popstate', function(event) { 
//   window.history.back();
// });


const Header = () => {
  return (
    <nav className="main-header navbar  navbar-theme navbar-fixed-top">
      <ul className="navbar-nav barIconNav">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" role="button" onClick={showHideSidebar}><i className="fa fa-bars"></i></a>
        </li>
      </ul>

      <ul className="navbar-nav navbar-right dpUI">

        <li className="nav-item">
          <a href className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
            <i className="fa fa-bell" ></i>
            <span className="badge badge-danger navbar-badge" id="notificationCountHead"> </span>
          </a>
          <div className="dropdown-menu">
            <NotificationHeader />
          </div>
        </li>

        <li className="nav-item">
          &nbsp;
        </li>
      </ul>
    </nav>

  )
}

export default Header