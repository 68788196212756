import React from 'react'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import ProductListByCategory from './ProductListByCategory'


const ProductsDashboard = () => {
    const { data, error, isLoaded } = GetRequest("/Product/GetProductCategories");

    if (error !== null) {
        return <Error data="Product List" />;
    }
    if (!isLoaded) {
        return <Loading />;
    } else {
        addFilter();
      }
    
      function addFilter() {
        setTimeout(function () {
          let list = document.querySelectorAll('.listfilterhead');
          let itemBox = document.querySelectorAll('.itemfiltered');
    
          for (let i = 0; i < list.length; i++) {
            list[i].addEventListener('click', function () {
              for (let j = 0; j < list.length; j++) {
                list[j].classList.remove('active');
              }
              this.classList.add('active');
    
              let dataFilter = this.getAttribute('data-filter');
    
              for (let k = 0; k < itemBox.length; k++) {
                itemBox[k].classList.remove('active');
                itemBox[k].classList.add('hide');
    
                if (itemBox[k].getAttribute('data-item') == dataFilter || dataFilter == "all") {
                  itemBox[k].classList.remove('hide');
                  itemBox[k].classList.add('active');
                }
              }
            })
          }
        }, 3000);
      }

      function textFilterProducts() {
        var input, filter, ul, li, a, i, txtValue;
        if(document.getElementById("searchProductFilter") !== null) {
            input = document.getElementById("searchProductFilter");
            filter = input.value.toUpperCase();
            ul = document.getElementById("accordion");
            li = ul.getElementsByTagName("label");
            for (i = 0; i < li.length; i++) {
                a = li[i];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    li[i].parentElement.parentElement.parentElement.parentElement.parentElement.style.display = "";
                } else { 
                li[i].parentElement.parentElement.parentElement.parentElement.parentElement.style.display = "none";
                }
            }
        }
    }

    return (
        <>

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card noUI">
                                <div className="card-header">
                                    <button className="form-control flBtn listfilterhead active" data-filter="all"> All </button>
                                    {

                                        data.result.map((item, index) => (
                                            <>
                                                <button className="form-control flBtn listfilterhead" data-filter={item.id} id={item.id}> {item.name}  </button>
                                            </>
                                        ))
                                    }

                                    <div className="dropdown NOfilter">
                                        <a href type="button" className="dropdown-toggle" data-bs-toggle="dropdown">
                                        <i className="fa fa-search prdSear"></i>
                                        </a>
                                        <ul className="dropdown-menu">
                                            <input className="form-control" id="searchProductFilter" onKeyUp={textFilterProducts}  type="text" placeholder="Search Product.." />
                                            {/* <li><hr className="dropdown-divider"></hr></li>
                                            {
                                                data.result.map((item, index) => (
                                                    <>
                                                        <li><a className="dropdown-item listfilterhead" href data-filter={item.id} id={item.id}> {item.name}  </a></li>
                                                    </>
                                                ))
                                            } */}

                                        </ul>
                                    </div>

                                </div>
                                <div className="card-body">
                                    <div id="accordion">
                                        <div className='row'>
                                            { 
                                                data.result.map((item, index) => (
                                                    <>
                                                        <ProductListByCategory id={item.id} />
                                                    </>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProductsDashboard