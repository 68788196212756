import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
const AddChannelPartner = () => {
    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({});
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }
    function setBasicDistId(inputs) {
        inputs.distributorId = distID;
    }
    const handleAddChannelPartnerSubmit = (event) => {
        event.preventDefault();
        if (Object.keys(inputs).length !== 0) {
            setBasicDistId(inputs);
            axios.post("https://dpapi.boosterparis.in/api/DistributorChannelPartner/AddDistributorChannelPartners", inputs, {
                headers: {
                    "Cache-Control": "no-cache",
                    "Access-Control-Allow-Origin": "*",
                    'Authorization': localStorage.getItem("loginToken"),
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        navigate("/MyChannel");
                    }
                }).catch((response) => {
                    if (response.code !== "ERR_NETWORK") {
                        // if(response.response.status === 401){  
                        //     window.location.replace("/Logout");
                        // }
                    } else {
                        console.log(response.code);
                    }
                    console.log(response.statusText);
                });
        } else {
        }
    }
    return (

        <section className="content">
            <div className="container-fluid">
                <br />
                <div className="row">
                    <div className="col-12">
                        <div className="card card-success">
                            <div className="card-header">
                                <h3 className="card-title">Add New Retailers</h3>
                            </div>
                            <div className="card-body addPartFC">
                                <form onSubmit={handleAddChannelPartnerSubmit} className='master-form ' novalidate>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Name</label>
                                                <input type="text" value={inputs.Name} onChange={handleChange} required name='Name' className="form-control" placeholder="Enter Name" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Legal Name</label>
                                                <input type="text" value={inputs.LegalName} onChange={handleChange} name='LegalName' className="form-control" placeholder="Enter Legal Name" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Gstin</label>
                                                <input type="text" value={inputs.Gstin} onChange={handleChange} name='Gstin' className="form-control" placeholder="Enter Gstin Number" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Pan</label>
                                                <input type="text" value={inputs.Pan} onChange={handleChange} name='Pan' className="form-control" placeholder="Enter Pan Number" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Email Id</label>
                                                <input type="text" value={inputs.EmailId} onChange={handleChange} name='EmailId' className="form-control" placeholder="Enter Email Id " />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Owner Name</label>
                                                <input type="text" value={inputs.PrimaryOwnerName} onChange={handleChange} name='PrimaryOwnerName' className="form-control" placeholder="Enter Owner Name" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Contact Person  </label>
                                                <input type="text" value={inputs.ContactPerson} onChange={handleChange} name='ContactPerson' className="form-control" placeholder="Enter Contact Person 1" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Phone Number 1</label>
                                                <input type="text" value={inputs.PhoneNumber1} onChange={handleChange} name='PhoneNumber1' className="form-control" placeholder="Enter Phone Number" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>WhatsAppNumber1</label>
                                                <input type="text" value={inputs.WhatsAppNumber1} onChange={handleChange} name='WhatsAppNumber1' className="form-control" placeholder="Enter WhatsApp  Number" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>PinCode</label>
                                                <input type="text" value={inputs.PinCode} onChange={handleChange} name='PinCode' className="form-control" placeholder="Enter PinCode" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>AddressLine1</label>
                                                <input type="text" value={inputs.AddressLine1} onChange={handleChange} name='AddressLine1' className="form-control" placeholder="Enter AddressLine1" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>AddressLine2</label>
                                                <input type="text" value={inputs.AddressLine2} onChange={handleChange} name='AddressLine2' className="form-control" placeholder="Enter AddressLine2" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>AddressLine3</label>
                                                <input type="text" value={inputs.AddressLine3} onChange={handleChange} name='AddressLine3' className="form-control" placeholder="Enter AddressLine3" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>State</label>
                                                <input type="text" value={inputs.State} onChange={handleChange} name='State' className="form-control" placeholder="Enter State" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>District</label>
                                                <input type="text" value={inputs.District} onChange={handleChange} name="District" className="form-control" placeholder="Enter District" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Taluka</label>
                                                <input type="text" value={inputs.Taluka} onChange={handleChange} name='Taluka' className="form-control" placeholder="Enter Taluka " />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Village</label>
                                                <input type="text" value={inputs.Village} onChange={handleChange} name="Village" className="form-control" placeholder="Enter Village" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>RegType</label>
                                                <input type="text" value={inputs.RegType} onChange={handleChange} name='RegType' className="form-control" placeholder="Enter RegType " />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label>Other Details</label>
                                                <textarea value={inputs.otherDetaails} onChange={handleChange} name="otherDetaails" className="form-control" rows="3" placeholder="Enter Other Details"></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12"  >
                                            <input type="submit" className="btn btn-success float-right submit-btn" value="Add  Retailers " />

                                        </div>
                                    </div>

                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section >

    )
}



export default AddChannelPartner