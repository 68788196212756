import React from 'react'

const PartnerProfile = () => {
    return (
        <section className="content">
            <div className="container-fluid"> 
                <div className="row"> 
                    <div className="col-md-12">
                        <div className="card card-warning"> 
                            <div className="card-header">
                                <h3 className="card-title">My Retailers  Profile details</h3>   
                            </div>
                            <div className="card-body">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}
export default PartnerProfile