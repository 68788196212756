import React from 'react'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import { Link, useParams } from 'react-router-dom'


const BPUPaymentList = () => {
  const Id = useParams();
  const distID = localStorage.getItem('distributorId');
  const boostID = localStorage.getItem('companyIdBooster');
  const parisID = localStorage.getItem('companyIdParis');
  const bpuID = localStorage.getItem('userId');
  const bpuType = localStorage.getItem('userType');
  const receiptSearchStatus = localStorage.getItem('receiptSearchStatus');

  var urlGetOrders = `/payment/GetPaymentsByDistributor/${Id.Id}/${bpuType}/${bpuID}`

  if (receiptSearchStatus !== null && receiptSearchStatus != "") {
    if (receiptSearchStatus == "All")
      urlGetOrders = `/payment/GetPaymentsByDistributor/${Id.Id}/${bpuType}/${bpuID}`
    else
      urlGetOrders = `/payment/GetPaymentReceiptsWithStatus/${bpuID}/${receiptSearchStatus}`;
  }
  else {
    urlGetOrders = `/payment/GetPaymentsByDistributor/${Id.Id}/${bpuType}/${bpuID}`
  }

  const { data, error, isLoaded } = GetRequest(urlGetOrders);

  if (error !== null) {
    return <Error data="My Distributor Payments " />;
  }
  if (!isLoaded) {
    return <Loading />;
  }

  return (
    <section className="content">
      <div className="container-fluid">
        <div className="card card-success">
          <div className="card-header">
            <h3 className="card-title">  Distributor Payments </h3>
          </div>
          <div className="card-body no-padding row">
            {
              data.result && data.result.map((item, index) => (
                <div className="col-md-4">
                  <div className="card card-primary">
                    <div className="card-header ">
                      <h5 className="card-title">   <label> <span className='trPayByCap'>{item.voucherNumber} </span>- Rs.<b>{item.totalPayment}</b><span className='trPayByAmtHi'> /- </span>  </label>  </h5>
                    </div>
                    <div className="card-body">
                      <p> Transaction# : <label> {item.transactionId}</label></p>
                      <p> Date : <label> {item.paymentDateStr}</label></p>
                      <p> Payment For Company : <label> {item.paymentForCompany}</label></p>
                      <p>Status : <b>{item.statusDisplayText}</b></p>
                      <p>Party : <b>{item.distributorDisplayName}</b></p>
                      {item.approvedBy != null ? <p>   Approved by: <b>{item.approvedBy}</b>  </p> : ''}
                      <div className='btnmptbl'>
                        <Link to={`/BPUDashboard/BPUPaymentDetails/${item.paymentId}`} id={item.paymentId} className='btn btn-success btn-sm'> View </Link>
                        {/* <Link to={`/BPUDashboard/BPDemoRoute`}  className='btn btn-success btn-sm hide' > View 2 </Link> */}

                        {item.status === "PendingForReview" || item.status === "InCompleteInformation" ? <Link to={`/MyPayments/PaymentEdit/${item.paymentId}`} id={item.paymentId} className='btn btn-primary btn-sm'> Edit </Link>
                          : ''}
                      </div>
                    </div>
                  </div>
                </div>

              ))
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default BPUPaymentList