import React from 'react'
import { Outlet } from "react-router-dom";
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';   
import { Link, useParams, useNavigate, Navigate } from 'react-router-dom';

function sidebarOff(){  
  if(window.innerWidth < 991){
    document.querySelector('.boostapp').classList.remove('active');  
  }
}
 
const checkLoginToken = localStorage.getItem('loginToken');

// let initialPoint;

// function handleTouchStart(e) { 
//     initialPoint = e.touches[0].pageX;
// }

// function handleTouchEnd(e) { 
//     let finalPoint = e.changedTouches[0].pageX; 
//     let difference = initialPoint - finalPoint; 
//     if (Math.abs(difference) > 100) {
//         if (difference > 0) {
//           document.querySelector('.boostapp').classList.remove('active'); 
//         } else { 
//           document.querySelector('.boostapp').classList.add('active'); 
//         }
//     }
// }

// document.addEventListener('touchstart', handleTouchStart, false);
// document.addEventListener('touchend', handleTouchEnd, false);

const Dashboard = () => {

  const urlSearchString = window.location.search; 
  if(urlSearchString !== ""){
      const params = new URLSearchParams(urlSearchString);   
      const Btoken = "Bearer " + params.get('token')
      localStorage.setItem("distributorId", params.get('distributorId'));
      localStorage.setItem('loginToken', Btoken);                    
      localStorage.setItem("distributorDisplayName", params.get('distributorName'));
      localStorage.setItem("userType", params.get('userType'));
      localStorage.setItem("userId", params.get('userId')); 
      localStorage.setItem("companyIdBooster", params.get('companyIdBooster'));
      localStorage.setItem("companyIdParis", params.get('companyIdParis')); 
      if(localStorage.getItem("userType") === "BPUser"){
        window.location.replace('/BPUDashboard/BPUDashContent');  
      } 
  }
  
setTimeout(function(){
  const checkUserType = localStorage.getItem('userType');
  if(checkUserType == 'BPUser') {
    document.getElementById('distributorNavList').classList.add('hide');
    document.getElementById('bpUserNavList').classList.remove('hide');
  } 
}, 1000);

  // if (checkLoginToken === null && checkLoginToken != '') {
  //   return <Navigate to="/Login" />;
  // }  
 
  return (
    <> 
      <Header />
      <Sidebar />
      <div className='content-wrapper' onClick={sidebarOff}> 
        <div className='container-fluid'> 
            <Outlet className="drop-in"></Outlet>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Dashboard