import React from 'react';
import ReactDOM from 'react-dom/client';  
import App from './App'; 
import "bootstrap/dist/css/bootstrap.min.css";  
import '../src/Assets/css/Custom.css?v=01';   
import "react-toastify/ReactToastify.css" 

 
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>  
          <App />  
  </React.StrictMode>
);
