import React from "react";
import ss3 from '../Assets/img/Schemes/ss3.png'; 
import ss2 from '../Assets/img/Schemes/ss2.png'; 
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import { Link } from "react-router-dom";

const SchemesSlider = () => {
    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');
 
    const { data, error, isLoaded } = GetRequest(`/Scheme/GetRunningSchemesSummary/${distID}`);
    
    if (error !== null) {
    return <Error data="Schemes " />;
    }
    if (!isLoaded) {
    return  <Loading />;
    }
 
    if(data.result.length !== 0) {
        return (        
            <div className="row">
               <div className="col-md-12">
                   <div className="card"> 
                       <div className="card-body">
                            <div id="schemeSliderDiv"  className="carousel slide" data-bs-ride="carousel">                                    
                                   <div className="carousel-inner">  
                                       { 
                                          data.result && data.result.map((item, index) => (
                                               <div  className={`carousel-item ${ index===0  ? 'active' : ''}`}>
                                                   <Link   to={`/SchemesDetails/${item.id}`} id={item.id}>
                                                       <img src={item.schemeCoverImage1} alt=""  className="d-block w-100"   height="" />
                                                       <div className="carousel-caption">
                                                           <h4>{item.name}</h4> 
                                                           <p>Start - {item.fromDate.slice(0, 10)} to End {item.toDate.slice(0, 10)}</p>
                                                       </div>
                                                   </Link>
                                               </div>
                                           ))
                                       } 
                                   </div> 
                                  
                                   <button className="carousel-control-prev" type="button" data-bs-target="#schemeSliderDiv" data-bs-slide="prev">
                                       <span className="carousel-control-prev-icon"></span>
                                   </button>
                                   <button className="carousel-control-next" type="button" data-bs-target="#schemeSliderDiv" data-bs-slide="next">
                                       <span className="carousel-control-next-icon"></span>
                                   </button>
                               </div>
                       </div>
                   </div>
               </div>
           </div> 
        )
    } else {
        return (        
            <>
           </> 
        )
    }    
}

export default SchemesSlider;