import React from 'react'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import { Link } from 'react-router-dom'

const NotificationHeader = () => {
    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');
    const { data, error, isLoaded } = GetRequest(`/Notification/GetListOfUnReadNotifications/${distID}`);

    if (error !== null) {
        return <Error data="Notifications " />;
    }
    if (!isLoaded) {
        return  <Loading />;
    }
    setTimeout(function(){
        if(data !== '') {
            document.getElementById('notificationCountHead').textContent = data.result.length;
        } else {
            document.getElementById('notificationCountHead').textContent = 0;
        }
      }, 1000);
   
 
  return (
    <>
         <span className="dropdown-item dropdown-header">{data.result.length}  Notifications</span>
         { 
            data.result.map((item, index) => ( 
                <>
                    <div className="dropdown-divider"></div> 
                       <Link to={`${item.alertLink}`} className="dropdown-item nthl">
                        <i className="fa fa-envelope mr-2"></i> {item.alertText}
                        <span className="dp-right text-muted text-sm">{item.lifeTimeStartDate.slice(0,10)}</span>
                        </Link>
                </>
            ))
        }   
        <Link to={'/NotificationDashboard'}  className="dropdown-item dropdown-footer" >See All Notifications </Link>
        
    </>
  )
}

export default NotificationHeader