import React from 'react'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import { Link } from 'react-router-dom'
import $, { event } from 'jquery';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";

const ShareObservations = () => {

    const navigate = useNavigate();
    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');
    const [inputs, setInputs] = useState({});
    const [file, setFile] = useState()

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }
    function handleChangeFile(event) {
        setFile(event.target.files[0])
        const name = event.target.name;
        const value = event.target.files[0];
        setInputs(values => ({ ...values, [name]: value }))
    }
    function setBasicDistId(inputs) {
        inputs.distributorId = parseInt(distID);
    }
    const handleSubmitShareObservation = (event) => {
        event.preventDefault();
        setBasicDistId(inputs);
        axios.post(`https://dpapi.boosterparis.in/api/ChannelObservation/AddNewShareObservation`, inputs, {
            headers: {
                "content-type": "multipart/form-data",
                "Cache-Control": "no-cache",
                "Access-Control-Allow-Origin": "*",
                'Authorization': localStorage.getItem("loginToken"),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    navigate("/ChannelObservationList");
                }
            }).catch((response) => {
                // if(response.response.status === 401){  
                //     window.location.replace("/Logout");
                //  }
                console.log(response.statusText);
            });
    }

    $(document).on('change', '.file-input', function () {
        var filesCount = $(this)[0].files.length;
        var textbox = $(this).prev();
        var replicaText = $('.file-input0').prev();
        if (filesCount === 1) {
            var fileName = $(this).val().split('\\').pop();
            textbox.text(fileName);
            replicaText.text(fileName)
        } else {
            textbox.text(filesCount + ' files selected');
            replicaText.text(filesCount + ' files selected');
        }
    });


    return (
        <>
            <section className="content">
                <div className="container-fluid">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header bg-gray">
                                <h3 className="card-title"> शंका निरसन/ प्रश्न विचारा / किंवा माहिती पाठवा </h3>
                            </div>

                            <div className="card-body nobornopad">
                                <form onSubmit={handleSubmitShareObservation} className='workguidenceshareobs master-form' noValidate>
                                    <div className="form-group">
                                        <label for="name By" className="form-label">प्रश्न/माहिती/Question/Query :</label>
                                        <input type="text" name='QuestionInfo' className="form-control" placeholder="Please write question here/आपला प्रश्न इथे नोंदवा"
                                            required onChange={handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label for="CropDetails" className="form-label">पीक/Crop Details</label>
                                        <textarea name='CropDetails' className="form-control txtDetailsHt" placeholder="Crop details/पिकाबद्दल माहिती" onChange={handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label for="locationInfo" className="form-label">ठिकाण (लोकेशन)/Location</label>
                                        <textarea name='locationInfo' className="form-control txtDetailsHt" placeholder="Location details (district/Taluka/village etc.)/ठिकाण (लोकेशन)/गाव-तालुका "
                                            onChange={handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label for="ContactInformation" className="form-label">संपर्क/Contact Details Of Farmer</label>
                                        <textarea name='ContactInformation' className="form-control txtDetailsHt" placeholder="Enter contact information here .../शेतकऱ्याबद्दल माहिती.."
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label for="AdditionalInfo" className="form-label">अधिक माहिती/Additional Information</label>
                                        <textarea name='AdditionalInfo' className="form-control txtDetailsHt" placeholder="Enter Additional Info here ... / आपल्या प्रश्नाबद्दल अधिक माहिती "
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label for="AdditionalInfo" className="form-label"><b>आपल्याकडे या प्रश्नासंदर्भात काही फोटो असतील ते कृपया अपलोड करा / Upload images related to your issue.</b></label>
                                    </div>
                                    <div className="form-group">
                                        <label for="Details " className="form-label">फोटो क्र. १ जोडा/Photo 1</label>
                                        <div className="file-drop-area">
                                            <span className="choose-file-button"  >Upload </span>
                                            <span className="file-message"> </span>
                                            <input className="file-input" type="file" multiple name="Image1" onChange={handleChangeFile} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label for="Details " className="form-label">फोटो क्र. 2 जोडा/Photo 2</label>
                                        <div className="file-drop-area">
                                            <span className="choose-file-button"  >Upload </span>
                                            <span className="file-message"> </span>
                                            <input className="file-input" type="file" multiple name="Image2" onChange={handleChangeFile} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label for="Details " className="form-label">फोटो क्र. 3 जोडा/Photo 3</label>
                                        <div className="file-drop-area">
                                            <span className="choose-file-button"  >Upload </span>
                                            <span className="file-message"> </span>
                                            <input className="file-input" type="file" multiple name="Image3" onChange={handleChangeFile} />
                                        </div>
                                    </div> <div className="form-group">
                                        <label for="Details " className="form-label">फोटो क्र. 4 जोडा/Photo 4</label>
                                        <div className="file-drop-area">
                                            <span className="choose-file-button"  >Upload </span>
                                            <span className="file-message"> </span>
                                            <input className="file-input" type="file" multiple name="Image4" onChange={handleChangeFile} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <br />
                                            <input type="submit" className="btn btn-success float-right submit-btn-sp" value="प्रश्न पुढे पाठवा " />
                                        </div>
                                        <div className="col-sm-4">
                                            <br />
                                            <input type="submit" className="btn btn-danger float-right submit-btn-sp" value="रद्द करा " />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ShareObservations