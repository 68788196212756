
import React from 'react'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import ss3 from '../Assets/img/Schemes/ss3.png'; 


const OurTeamContacts = () => {
    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');

    const { data, error, isLoaded } = GetRequest(`/User/GetTeamContactsForDistributor/${distID}`);

    if (error !== null) {
        return <Error data="Team Contacts" />;
    }
    if (!isLoaded) {
        return <Loading />;
    }
 
  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row"> 
            <div className="col-md-12">
              <div className="card  card-primary">
                <div className="card-header ">
                  <h3 className="card-title"> 
                  Team Contacts
                  </h3>
                </div>
                <div className="card-body no-padding">
                <div className="row"> 
                {
                    data.result.map((item, index) => (
                      <div className="col-md-6"> 
                     <div className="card card-warning">
                                                        <div className="card-header ">
                                                            <h5 className="card-title">   {item.teamMemberName} </h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <h6>{item.designation}</h6>
                                                            <p>Contact:{item.mobileNumber}</p>                                                           
                                                        </div>
                                                    </div>
                    </div>
                  ))
                } 
                 </div>
                </div> 

              </div>
            </div> 

          </div>
        </div>
      </section>
    </>
  )
}

export default OurTeamContacts