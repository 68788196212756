import React from 'react'

const PageTopHeadTitleBread = (props) => {
  return (
    <>
        <div className="content-header dp-bred-head" style={{display: "none"}}>
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">{props.title}</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb dp-right">
                            <li className="breadcrumb-item"><a href>Home</a></li>
                            <li className="breadcrumb-item active">{props.title} </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

    </>
  )
}

export default PageTopHeadTitleBread