import React from 'react'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import { Link } from 'react-router-dom'

const MyPaymentsList = () => {
  const distID = localStorage.getItem('distributorId');
  const boostID = localStorage.getItem('companyIdBooster');
  const parisID = localStorage.getItem('companyIdParis');
  const userId = localStorage.getItem('userId');
  const userType = localStorage.getItem('userType');

  const { data, error, isLoaded } = GetRequest(`/payment/GetPaymentsByDistributor/${distID}/${userType}/${userId}`);

  if (error !== null) {
    return <Error data="My Payments " />;
  }
  if (!isLoaded) {
    return <Loading />;
  }

  return (
    <div className='row'>
      {
        data.result && data.result.map((item, index) => (
          <div className="col-md-4">
            <div className="card card-primary">
              <div className="card-header ">
                <h5 className="card-title">   <label> <span className='trPayByCap'>{item.displayVoucherNumber} </span> - Rs.<b>{item.totalPayment}</b><span className='trPayByAmtHi'> /- </span>  </label>  </h5>
              </div>
              <div className="card-body">
                <p> Transaction Id : <label> {item.transactionId}</label></p>
                <p> Date : <label> {item.paymentDateStr}</label></p>
                <p> Payment For Company : <label> {item.paymentForCompany}</label></p>
                <p>Status : <label><b>{item.statusDisplayText}</b></label></p>
                {item.approvedBy != null ? <p>   Approved by: <b>{item.approvedBy}</b>  </p> : ''}
                <div className='btnmptbl'>
                  <Link to={`/MyPayments/PaymentDetails/${item.paymentId}`} id={item.paymentId} className='btn btn-success btn-sm'> View </Link>

                  {item.status === "PendingForReview" || item.status === "InCompleteInformation" ? <Link to={`/MyPayments/PaymentEdit/${item.paymentId}`} id={item.paymentId} className='btn btn-primary btn-sm'> Edit </Link>
                    : ''}
                </div>

              </div>
            </div>
          </div>

        ))
      }
    </ div>
  )
}

export default MyPaymentsList