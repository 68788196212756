import React, { useEffect } from 'react'
import { useState, useRef } from 'react';
import GetSelectOptionList from './GetSelectOptionList';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import $ from 'jquery';


const SubmitPaymentForm = () => {
    const [file, setFile] = useState()
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({});
    const [inputsNewBank, setInputsNewBank] = useState({});
    const [selectVal, setSelectVal] = useState("");
    const [selectFormVal, setSelectFormVal] = useState("");
    const [selectPayModeVal, setSelectPayModeVal] = useState("");
    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');
    const [fileOrPicture, setFileOrPicture] = useState();
    const [optUpdate, setOPTUpdate] = useState('');
    const [getCompBankID, setGetCompBankID] = useState(boostID);
    const [getdistbkLt, setGetdistbkLt] = useState(distID);
    const [subValidMsg, setSubValidMsg] = useState();
    const [compidstate, setCompidstate] = useState(boostID);

    const tostDismissAll = () => toast.clearWaitingQueue();

    const notifySuccess = () => toast.success('Your New Bank Account Added Successfully!', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "colored"
    });
    const notifySuccessParis = () => toast.success('Paris company selected for making payments!', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "colored"
    });
    const notifySuccessBooster = () => toast.success('Booster company selected for making payments!', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "colored"
    });

    function handleChangeFile(event) {
        setFile(event.target.files[0])
        const name = event.target.name;
        const value = event.target.files[0];
        setInputs(values => ({ ...values, [name]: value }))

    }
    const handleChangeNewBank = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputsNewBank(values => ({ ...values, [name]: value }))
    }
    setTimeout(function () {
        document.getElementById('Payment-submit-stepper-head').classList.add('active');
        document.getElementById('Payment-distribution-stepper-head').classList.remove('active');
        document.getElementById('Payment-summary-stepper-head').classList.remove('active');
    }, 200);

    const handleSelectChange = (event) => {
        setSelectVal(event.target.value)
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }
    const handleSelectChangeBankId = (event) => {
        setSelectVal(event.target.value)
        const name = event.target.name;
        const value = event.target.value;
        setInputsNewBank(values => ({ ...values, [name]: value }))
    }
    const handleSelectFormChange = (event) => {
        setSelectFormVal(event.target.value)
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }
    const handlePayModeChange = (event) => {
        setSelectPayModeVal(event.target.value)
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }
    const handleDateFormatChange = (event) => {
        const fomDate = event.target.value;
        const fomDateloop = fomDate.split('-');
        const dd = fomDateloop[2];
        const mm = fomDateloop[1];
        const yy = fomDateloop[0];
        const formatOk = `${dd}/${mm}/${yy}`;
        const name = event.target.name;
        setCurrentDate(fomDate);
        setInputs(values => ({ ...values, [name]: formatOk }))
    }

    const showHideSection = (datare) => {
        document.getElementById('totalePayValueDis').value = datare.amountPaid;
        document.getElementById('advPayValueDis').value = datare.amountPaid;
        document.getElementById('paymentIdResData').value = datare.paymentId;
        document.getElementById('amountPaidResData').value = datare.amountPaid;
    }

    const handlePaymentSubmit = (event) => {
        event.preventDefault();
        loaderToggle();
        if (Object.keys(inputs).length !== 0) {
            setBasicDistId(inputs);
            setBasicCompId(inputs);
            setTransactionDate(inputs);

            // console.log(inputs);
            axios.post("https://dpapi.boosterparis.in/api/Payment/SubmitPayment", inputs, {
                headers: {
                    "content-type": "multipart/form-data",
                    "Cache-Control": "no-cache",
                    "Access-Control-Allow-Origin": "*",
                    'Authorization': localStorage.getItem("loginToken"),
                },

            })
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.isSuccess === false) {
                            setSubValidMsg(response.data.message);
                            document.getElementById('clickSubmitValidError').classList.remove('hide');
                            loaderToggle();
                            // toggleServerError();
                        } else {
                            localStorage.setItem('currentSelectedCompId', response.data.result.companyId);
                            showHideSection(response.data.result);
                            loaderToggle();
                            navigate("/NewPayment/PaymentDistribution");
                        }
                    }
                }).catch((response) => {
                    toggleError();
                    loaderToggle();
                    // if(response.response.status === 401){  
                    //     window.location.replace("/Logout");
                    //  }
                });
        } else {
            loaderToggle();
            toggleError();
        }
    }
    function closeErrorMsg() {
        document.getElementById('clickSubmitValidError').classList.add('hide');
    }
    function toggleServerError() {
        setTimeout(function () {
            document.getElementById('clickSubmitValidError').classList.add('hide');
        }, 10000);
    }
    function setBasicDistId(inputs) {
        inputs.distributorId = document.getElementById("localDistId").value;
    }
    function setBasicCompId(inputs) {
        inputs.companyId = document.getElementById("localCompActiveId").value;
    }

    function setTransactionDate(inputs) {
        const fomDate = document.getElementById("transactionDate").value;
        const fomDateloop = fomDate.split('-');
        const dd = fomDateloop[2];
        const mm = fomDateloop[1];
        const yy = fomDateloop[0];
        const formatOk = `${dd}/${mm}/${yy}`;
        inputs.transactionDate = formatOk;
    }

    function toggleError() {
        document.getElementById('clickSubmitError').classList.toggle('hide');
        setTimeout(function () {
            if (document.getElementById('clickSubmitError') != '') {
                document.getElementById('clickSubmitError').classList.add('hide');
            }
        }, 10000);
    }

    function loaderToggle() {
        document.getElementById('loaderFormSubmit').classList.toggle('hide');
    }

    const handleAddDistNewBankAccount = (event) => {
        event.preventDefault();
        setGetdistbkLt(11111);
        setBasicDistId(inputsNewBank);
        axios.post("https://dpapi.boosterparis.in/api/Bank/AddDistributorBankAccount", inputsNewBank, {
            headers: {
                "Cache-Control": "no-cache",
                "Access-Control-Allow-Origin": "*",
                'Authorization': localStorage.getItem("loginToken"),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    tostDismissAll();
                    notifySuccess();
                    setGetdistbkLt(distID);
                }
            }).catch((response) => {
                // console.log(response.statusText);
                // if(response.response.status === 401){  
                //     window.location.replace("/Logout");
                //  }
            });
    }

    const handleCompRadioMaster = (event) => {
        const compIdSet = event.currentTarget.id;
        if (compIdSet == "BoosterSwitchPayment") {
            setCompidstate(boostID);
            tostDismissAll();
            notifySuccessBooster();
            setGetCompBankID(boostID);
            document.getElementById('BoosterSwitchPayment').classList.add('active')
            document.getElementById('ParisSwitchPayment').classList.remove('active')

        } else {
            if (compIdSet == "ParisSwitchPayment") {
                setCompidstate(parisID);
                tostDismissAll();
                notifySuccessParis();
                setGetCompBankID(parisID);
                document.getElementById('BoosterSwitchPayment').classList.remove('active')
                document.getElementById('ParisSwitchPayment').classList.add('active');

            }
        }
    }

    $(document).on('change', '.file-input', function () {
        var filesCount = $(this)[0].files.length;
        var textbox = $(this).prev();
        var replicaText = $('.file-input0').prev();
        if (filesCount === 1) {
            var fileName = $(this).val().split('\\').pop();
            textbox.text(fileName);
            replicaText.text(fileName)
        } else {
            textbox.text(filesCount + ' files selected');
            replicaText.text(filesCount + ' files selected');
        }
    });

    const [currentDate, setCurrentDate] = useState('');

    useEffect(() => {
        // Get today's date
        const today = new Date();

        // Format it to "yyyy-mm-dd" (which is the required format for input type="date")
        const formattedDate = today.toISOString().substr(0, 10);

        setCurrentDate(formattedDate);
    }, []);


    return (
        <>
            <div className="col-12">
                <div className="card">
                    <div className="card-header bg-gray">
                        <h3 className="card-title">Transaction Details</h3>
                    </div>

                    <div className="card-body nobornopad">
                        <form onSubmit={handlePaymentSubmit} className='master-form' novalidate>
                            <div className="row">

                                <div className="col-sm-12" style={{ textAlign: "center", marginBottom: '15px', borderBottom: '1px solid #b0b0b0' }}>
                                    <div className="form-group compSwitchBTnBg ">
                                        <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                            <button type="button" id="BoosterSwitchPayment" className="btn btn-info btn-my btn-block active" onClick={handleCompRadioMaster}  > Booster</button>
                                            <button type="button" id="ParisSwitchPayment" className="btn btn-info btn-my btn-block" onClick={handleCompRadioMaster} >Paris </button>
                                        </div>
                                        {/* <div className="form-checkbox">
                                            <label for="Booster">
                                                <input type="radio" id="Booster" name="flight-type" checked="true" onClick={handleCompRadioMaster} />
                                                <span></span>Booster
                                            </label>
                                            <label for="Paris">
                                                <input type="radio" id="Paris" name="flight-type"  onClick={handleCompRadioMaster} />
                                                <span></span>Paris
                                            </label>
                                        </div> */}
                                    </div>
                                </div>

                            </div>

                            <div className="row  card-body">
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label for="Amount Paid " className="form-label mandate">Amount</label>
                                        <input type="number" name='amountPaid' className="form-control" placeholder="Amount Paid  ..."
                                            value={inputs.amountPaid}
                                            onChange={handleChange} required pattern="^(([0-9]*)|(([0-9]*)\.([0-9]*)))$"
                                        />
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label for="Payment Mode " className="form-label mandate">Payment Mode</label>
                                        <select className="form-control" name='paymentMode' required value={selectPayModeVal} onChange={handlePayModeChange}>
                                            <option id="-1" value=""> Payment Mode</option>
                                            <option id="1" value="Cheque"> Cheque </option>
                                            <option id="2" value="BankTransfer">  Bank Transfer </option>
                                            <option id="3" value="UPI">  UPI </option>
                                            <option id="4" value="Cash"> Cash </option>
                                            <option id="5" value="Other"> Other </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label for="Transaction Id/Cheque# " className="form-label mandate">Transaction Id/Cheque#  </label>
                                        <input type="text" name='transactionId' required className="form-control" placeholder="Transaction Id/Cheque ..."
                                            value={inputs.transactionId}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                {/* <div className="col-sm-4">
                                    <div className="form-group">
                                        <label for="Date and time: " className="form-label">Instrument Date:</label>
                                        <input className="form-control" type="date" name='InstrumentDate' data-date-format="dd-mm-yyyy"
                                            onChange={handleDateFormatChange} pattern="^(0?[0-9]|[12][0-9]|3[01])[\/\-](0?[1-0]|1[012])[\/\-]\d{4}$" />
                                    </div>
                                </div> */}

                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label for="Date and time: " className="form-label mandate">Transaction/Deposite Date  </label>
                                        <input className="form-control" type="date" required name='transactionDate' id='transactionDate' data-date-format="dd-mm-yyyy"
                                            value={currentDate} onChange={handleDateFormatChange} pattern="^(0?[0-9]|[12][0-9]|3[01])[\/\-](0?[0-9]|1[012])[\/\-]\d{4}$" />
                                    </div>
                                </div>

                                {/* <div className="col-sm-4">
                                    <div className="form-group">
                                        <label for="Transaction By" className="form-label">Transaction By :</label>
                                        <input type="text" name='transactionBy' className="form-control" placeholder="Transaction By ..."
                                            value={inputs.transactionBy}
                                            onChange={handleChange}  
                                        />
                                    </div>
                                </div> */}
                                {/* <div className="col-sm-4">
                                    <div className="form-group">
                                        <label for="From Bank " className="form-label mandate plusBankAlign">
                                            Select Your Bank Account                                            
                                        </label>
                                        <i className="fa fa-plus addnewbka" data-bs-toggle="modal" data-bs-target="#AddDistNewBankAccountModal" ></i>
                                        <select className="form-control" name='fromBankId' id="banklistupdateinstant" required value={selectFormVal} onChange={handleSelectFormChange}>
                                            <option value=""> Select Your Bank Account</option> 
                                            <GetSelectOptionList name={`GetDistributorBanks/${getdistbkLt}`}    />

                                        </select>
                                    </div>
                                </div> */}
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label for="To Bank " className="form-label mandate">Booster/Paris Bank Account</label>
                                        <select className="form-control" name='toBankId' required value={selectVal} onChange={handleSelectChange}>
                                            <option value=""> Select Booster/Paris Bank Account</option>
                                            <GetSelectOptionList name={`GetCompanyBanks/${getCompBankID}`} />
                                        </select>
                                    </div>
                                </div>

                                {/* <div className="col-sm-4">
                                    <div className="form-group">
                                        <label for="Details " className="form-label">Sales Team/Handover Details</label>
                                        <input type="text" name='SalesTeam_HandoverDetails' className="form-control" placeholder="Enter Sales Team/Handover Details ..."
                                            value={inputs.SalesTeam_HandoverDetails}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div> */}

                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label for="Details " className="form-label">Details</label>
                                        <textarea name='details' className="form-control txtDetailsHt" placeholder="Enter Details here ..."
                                            value={inputs.details}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <label for="Details " className="form-label">Upload Receipt </label>
                                    <div className="file-drop-area">
                                        <span className="choose-file-button"  >Upload files</span>
                                        <span className="file-message">or drag and drop files here</span>
                                        <input className="file-input" type="file" multiple name="File" onChange={handleChangeFile} />
                                    </div>
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-sm-5  card-body">
                                    <input type="hidden" name='distributorId' id="localDistId" className="form-control"
                                        value={distID}
                                    />
                                    <input type="hidden" name='companyId' id="localCompActiveId" className="form-control"
                                        value={compidstate}
                                    />
                                    <br />

                                    <input type="submit" className="btn btn-success float-right submit-btn" value="Submit Payment" />


                                </div>
                                <div className="col-sm-5  card-body">
                                    <span id="clickSubmitValidError" className='hide'>
                                        <div className="alert alert-danger alert-dismissible">
                                            <button type="button" className="btn-close" onClick={closeErrorMsg} ></button>
                                            <label>{subValidMsg} </label>
                                        </div>
                                    </span>

                                    <span id="clickSubmitError" className='hide'>
                                        <div className="alert alert-danger alert-dismissible">
                                            <button type="button" className="btn-close"  ></button>
                                            <strong>Info!</strong>  “Something went wrong, Please contact administrator.”
                                        </div>
                                    </span>
                                </div>

                            </div>
                        </form>

                        <div id='loaderFormSubmit' className='col-md-12 hide'>
                            <div id="loaderScreen" ></div>
                        </div>
                        <div className="modal fade" id="UploadPhotoOrTakeCameraPhototModal">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title"> Upload Receipt Or Take Picture </h4>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="col-sm-12">
                                            <label for="Details " className="form-label">Upload Receipt </label>
                                            <div className="file-drop-area">
                                                <span className="choose-file-button"  >Upload  </span>
                                                <span className="file-message"> </span>
                                                <input className="file-input" type="file" multiple name="File" onChange={handleChangeFile} />
                                            </div>
                                        </div>
                                        <div className='orCenterText'><span>OR</span> </div>
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label for="Details " className="form-label">Take Picture</label>
                                                <div>

                                                    <button className="btn btn-success mycampbtn"    >Camera</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='modal-footer'>
                                        <button type="button" className="btn btn-danger btn-100 dp-right" id='closeUploadModal' data-bs-dismiss="modal">Close</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="AddDistNewBankAccountModal">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title"> Add Your New Bank Account </h4>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                                    </div>
                                    <div className="modal-body">
                                        <form onSubmit={handleAddDistNewBankAccount} novalidate>

                                            <div className="mb-3 mt-3">
                                                <label for="BankNameList " className="form-label mandate">Select Bank Name :</label>
                                                <select className="form-control" name='BankId' required value={selectVal} onChange={handleSelectChangeBankId}>
                                                    <option value=""> Select Bank Name </option>
                                                    <GetSelectOptionList name={`GetBankList`} />
                                                </select>

                                            </div>
                                            <div className="mb-3 mt-3">
                                                <label for="AccountName " className="form-label mandate">Bank Account Name :</label>
                                                <input type="text" id="AccountName" className="form-control"
                                                    placeholder="Enter Bank Account Name" name="AccountName"
                                                    onChange={handleChangeNewBank} required />
                                            </div>
                                            <div className="mb-3 mt-3">
                                                <label for="AccountNumber " className="form-label mandate">Bank Account Number :</label>
                                                <input type="text" id="qtyControl" className="form-control"
                                                    placeholder="Enter Account Number" name="AccountNumber"
                                                    onChange={handleChangeNewBank} required pattern="^(([0-9]*)|(([0-9]*)))$" />
                                            </div>
                                            <div className="mb-3 mt-3">
                                                <label for=" BranchName" className="form-label mandate">Branch Name  :</label>
                                                <input type="text" id="qtyControl" className="form-control"
                                                    placeholder="Enter Branch Name" name="BranchName"
                                                    onChange={handleChangeNewBank} required />
                                            </div>
                                            {/* <div className="mb-3 mt-3">
                                                <label for=" ShortName" className="form-label"> Enter Your Bank Account Short Name:</label>
                                                <input type="text" id="qtyControl" className="form-control"
                                                    placeholder="Enter Bank Account Short Name" name="ShortName"
                                                    onChange={handleChangeNewBank} />
                                            </div> */}
                                            <div className="mb-3 mt-3">
                                                <label for=" Ifsccode" className="form-label mandate"> IFSC Code :</label>
                                                <input type="text" id="qtyControl" className="form-control"
                                                    placeholder="Enter Ifsc code" name="Ifsccode"
                                                    onChange={handleChangeNewBank} required />
                                            </div>
                                            <input className="btn btn-primary btn-120" type="submit" data-bs-dismiss="modal" value="Add New Account" />
                                            <button type="button" className="btn btn-danger btn-100 dp-right" id='closeAddNewAccModal' data-bs-dismiss="modal">Close</button>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <ToastContainer limit={1} />
                    </div>
                </div>
            </div>
        </>
    )
}


export default SubmitPaymentForm

