import React from 'react'

const FormStepper = () => {
  return (
    <div className="bs-stepper">
        <div className="bs-stepper-header" role="tablist"> 
            <div className="step active" data-target="#addProduct-part" id="Payment-submit-stepper-head">
                <button type="button" className="step-trigger" role="tab" aria-controls="addProduct-part" >
                    <span className="bs-stepper-circle">1</span>
                    <span className="bs-stepper-label">TRN</span>
                </button>
            </div>
            <div className="line"></div>
            <div className="step" data-target="#payments-part" id="Payment-distribution-stepper-head">
                <button type="button" className="step-trigger" role="tab" aria-controls="payments-part" >
                    <span className="bs-stepper-circle">2</span>
                    <span className="bs-stepper-label">Distribution</span>
                </button>
            </div>
            <div className="line"></div>
            <div className="step" data-target="#placeorder-part1" id="Payment-summary-stepper-head">
                <button type="button" className="step-trigger" role="tab" aria-controls="placeorder-part1"  >
                    <span className="bs-stepper-circle">3</span>
                    <span className="bs-stepper-label">Summary</span>
                </button>
            </div>
        </div>
    </div>
  )
}

export default FormStepper