import React from 'react'
import growthchart from '../Assets/img/Profilibility/growthfw.png'
import growthcircle from '../Assets/img/Profilibility/gwcircle.png'

const Profitability = () => {
    return (
        <>
         <section className="content">
                <div className="container-fluid">
                    <h1 className='comesoon'> Coming Soon! </h1>
                </div>
            </section>
            <section className="content hide">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header bg-primary">
                                    <h3 className="card-title">Your Growth Readiness Score - Try it.</h3>

                                </div>

                                <div className="card-body">

                                    <div className="row">
                                        <div className="col-md-6" style={{ textAlign: 'center' }}>
                                            <img src={growthcircle} className='img-responsive' width="300" />

                                        </div>
                                        <div className="col-md-6 col-11" style={{ textAlign: 'center' }}>
                                            <img src={growthchart} className='img-responsive' width="350" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p>
                                                <ul>
                                                    <li>
                                                        Do you know all progressive farmers in your area ?
                                                    </li>
                                                    <li>
                                                        Do you know who is farmer's advisor in every village ? or to whom farmers follow ? or to whom farmer approaches for their queries ?
                                                    </li>
                                                    <li>
                                                        Do Farmer get required attention from your staff and you ?
                                                    </li>
                                                    <li>
                                                        Do you think something is missing or something can be improved as far as farmer connect is concerned ?
                                                    </li>
                                                    <li>
                                                        Do you know how Booster can help you to grow farmers in your area ?
                                                    </li>
                                                    <li>
                                                        If not, have you discussed with Booster authorities or sales representative about the same ?
                                                    </li>
                                                    <li>
                                                        Do you think advertising helps ? e.g. taking name of the brand, name of the organization, your name along with contact information will help in the growth ?
                                                    </li>
                                                    <li>
                                                        Have you listed what all things can be done to take your name to farmers with assistance from Booster Team ?
                                                    </li>
                                                    <li>
                                                        Do you think Farmer is getting required unbiased consultation from you or your staff ?
                                                    </li>
                                                    <li>
                                                        Do you believe social media and its impact ?
                                                    </li>
                                                    <li>
                                                        Have you joined all or important WhatsApp, Telegram groups ? or important Facebook pages ?
                                                    </li>
                                                    <li>
                                                        Have you checked potentials of providing consulting messages related to crop protection, growth, soil conditioning etc.. ? And Have you received any calls or enquiries for more details ?

                                                    </li>
                                                    <li>
                                                        Do you think you should keep sharing messages coming from Booster Paris Expert team and share it with Farmer groups ?
                                                    </li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card card-warning">
                                <div className="card-header">
                                    <h3 className="card-title">General Growth Tips</h3>

                                </div>

                                <div className="card-body">
                                    <p>
                                        <ul>
                                            <li>
                                                Farmer should never return from you or your partners.
                                            </li>
                                            <li>
                                                Close watch on your inventory will help you.
                                            </li>
                                            <li>
                                                Updating your stock will always keep you updated about current stock in hand.
                                            </li>
                                            <li>
                                                We are doing good effort to take important recommendeations to you and also providing platform to share these easily with farmers. This shall help you.
                                            </li>
                                            <li>
                                                Providing as much as possible consulting to farmers may help you to attract more farmers.  For this if need any assistance, we are just call away and very very happy to help you. We are happy to make you influencer in your area.
                                            </li>
                                            <li>
                                                Be in touch with Sales team. They can help you at every step and for every problem.
                                            </li>
                                            <li>
                                                Please Share Success Stories.
                                            </li>
                                            <li>
                                                Let us know which is next potential success story in your area... lets work towards it. Progressive Farmer is our brand ambassador on ground. Lets identify and help them grow.
                                            </li>
                                            <li>
                                                "Sankalp" - "Lets create Success Stories by working closely with farmers".
                                            </li>
                                            <li>
                                                Your suggestion to us will help to connect with more farmers - We have given sections in this platform to add Farmers, potential villages.
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card card-warning">
                                <div className="card-header">
                                    <h3 className="card-title">Profitability Analysis For you and your growth</h3>

                                </div>

                                <div className="card-body">
                                    <p>
                                        <ul>
                                            <li>
                                                You sold X items of X amounts. If you would have avaialed scheme - you would have earned 10% more.
                                            </li>
                                            <li>
                                                Fast moving products are not ordered.
                                            </li>
                                            <li>
                                                Recommended products are not present in your inventory.
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
           
        </>
    )
}

export default Profitability