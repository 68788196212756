import React from 'react'

const ExpiringStock = () => {
  return (
    <>
        <section className="content">
                <div className="container-fluid">
                    <h1 className='comesoon'> Coming Soon! </h1>
                </div>
            </section>
      <section className="content hide">
        <div className="container-fluid">

          <div className="card">
            <div className="card-header">
              <h3 className="card-title">
                Mango : आंबा फळपिक अंतिम टप्प्यात, योग्य जोपासणा केल्यासच फळ पडणार पदरात
              </h3>

           
            </div>
            <div className="card-body">
              आंब्याच्या झाडावरुन तो किती फळांची जोपासणा करु शकतो हे समजते. परिपक्व आंब्याचे झाडच कोयीचे रुपांतर आंब्यापर्यंत होईपर्यंत जोपासणा करु शकतात. पण कधी कधी झाड खूप जुनं झाल्यावर किती फळ परिपक्व होणार हे सुध्दा सांगता येणार नाही. अशा परिस्थितीत आंब्याच्या कैऱ्या पडतच राहतात. ज्यापासून बचावासाठी शेतकऱ्यांनी आंब्याच्या झाडांवर लक्ष ठेवणे आवश्यक आहे.
            </div>


          </div>


          <div className="card">
            <div className="card-header">
              <h3 className="card-title">
                अ‍ॅव्होकॅडो फळ लागवड
              </h3>

               
            </div>
            <div className="card-body">
              मराठवाडय़ासारख्या कायम पाण्याचे दुर्भिक्ष्य असणाऱ्या भागात मेक्सिकोतील अ‍ॅव्होकॅडो नावाच्या फळ पिकाची लागवड होत आहे. पैठण तालुक्यातील ब्रह्मगव्हाण परिसरात सहा एकरवर अ‍ॅव्होकॅडोची ५५० रोपांची लागवड करण्यात आली असून, आणखी ६५० रोपे लावण्यात येणार आहेत.  गंगामाई कृषी उद्योग संस्थेकडून अ‍ॅव्होकॅडोसह पिवळे खजूर, ड्रॅगन आदी विदेशी फळांच्या शेतीचे प्रयोग केले जात आहेत. अ‍ॅव्होकॅडो हे फळ मेक्सिकोतील आहे. आरोग्याच्या विविध समस्यांवर गुणकारी ठरत असल्याचा दावा केला जातो. विशेष म्हणजे अल्प पाण्यावर येणारे फळ आहे. अ‍ॅव्होकॅडोसाठी ३० अंशांपर्यंत तापमान लागते.
            </div>

          </div>

          <div className="card">
            <div className="card-header">
              <h3 className="card-title">
                पेरूच्या झाडाविषयी माहिती – पेरू शेती लागवड
              </h3>

             
            </div>
            <div className="card-body">
              पेरू हे आपल्या देशातील एक अतिशय लोकप्रिय फळ आहे, ज्याला गरिबांचे सफरचंद देखील म्हणतात. हे स्वादिष्ट असण्यासोबतच आरोग्यासाठी खूप फायदेशीर आहे. त्यात जीवनसत्त्वे, लोह, चुना, फॉस्फरससह अनेक खनिजे आढळतात. त्यात भरपूर प्रमाणात व्हिटॅमिन सी असते, त्यामुळे त्याचे अनेक आरोग्य फायदे आहेत. पेरू उत्पादनात भारताचा जगात चौथा क्रमांक लागतो. चला तर मग जाणून घेऊया पेरू बागकाम करण्याचा योग्य मार्ग कोणता आहे.
            </div>


          </div>
        </div>
      </section>
    </>
  )
}

export default ExpiringStock