import React from 'react'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import { Link } from 'react-router-dom'
import { Navigate } from 'react-router-dom';

const ProfileDashboard = () => {
    
    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis'); 
  
    // const { data, error, isLoaded } = GetRequest(`/DistributorChannelPartner/GetDistributorChannelPartners/${distID}`);
     

    // if (error !== null) {
    //     return <Error data=" " />;
    // }
    // if (!isLoaded) {
    //     return <Loading />;
    // }

    // const logoutUser = () => {
    //     localStorage.removeItem('loginToken');
    //     localStorage.removeItem('distributorId');
    //     window.location.replace("/Login" );
    // }
    return (

        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title"> My Profile </h3>   
                                <div className="rightAlgin"> 
                                    {/* <a href='/logout.html'>Logout</a> */}
                                   <Link to="/Logout" className='btn btn-success'  >Logout </Link>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                <div className="col-md-12">
                                  <p>
                                    <h6>Name :- {localStorage.getItem('distributorDisplayName')}</h6>
                                  </p>

                                </div>
                                </div>
                                <div className="row">
                                <div className="col-md-12">
                                  <p>
                                     
                                  </p>

                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </section>
    )
}
 
export default ProfileDashboard