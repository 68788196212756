import React from 'react'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import { Link } from 'react-router-dom'

const MyChannelDashboard = () => {

    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');

    const { data, error, isLoaded } = GetRequest(`/DistributorChannelPartner/GetDistributorChannelPartners/${distID}`);


    if (error !== null) {
        return <Error data="My Channel Partners " />;
    }
    if (!isLoaded) {
        return <Loading />;
    }
    if (data.result.length !== 0) {
        return (

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-success">
                                <div className="card-header">
                                    <h3 className="card-title"> My Retailers </h3> <Link to="/AddChannelPartner" className='btn btn-primary btn-sm fright'>Add </Link>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        {
                                            data.result && data.result.map((item, index) => (
                                                <div className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
                                                    <div className="card card-primary">
                                                        <div className="card-header">
                                                            <h5 className="card-title"> {item.name} </h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <label>Name - <b>{item.name} </b></label>  <br />
                                                            {/* <label>Email - {item.emailId} </label>  <br /> */}
                                                            <label>Mobile - <b>{item.phoneNumber1}</b> </label>  <br />
                                                            <label>WhatsApp - <b>{item.whatsAppNumber1}</b> </label>  <br />
                                                            <label>Contact Person - <b>{item.contactPerson}</b> </label>  <br />
                                                            <label>Address - <b>{item.addressLine1}</b> </label>  <br />
                                                            <label>Pin - <b>{item.pinCode}</b> </label>  <br />
                                                            {/* <label>Pan Number - {item.pan} </label>  <br />
                                                            <label>GST Number - {item.gstin} </label>  <br />
                                                            <label>Tan Number - {item.tan} </label>  <br /> */}
                                                            <label> {item.address ? `Address - ${item.address}` : ''} </label>
                                                            <p>
                                                                <br />  <Link to= {`/ViewMyChannelPartnerReport/${item.id}`}  className='btn btn-success btn-sm fright lfrv' id={item.id}>View Report </Link>
                                                                {/* <Link className='btn btn-primary btn-sm fright lfr' id={item.id}>View Profile </Link> */}
                                                                {/* <Link to="/AddChannelPartner" className='btn btn-info btn-sm fright lfr' id={item.id}>Edit </Link> */}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        )

    } else {
        return (
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-warning">
                                <div className="card-header">
                                    <h3 className="card-title"> My Channel Partners </h3> <Link to="/AddChannelPartner" className='btn btn-primary fright'>Add </Link>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <h4 className="NoDataAvailableText"> No Data Available </h4>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        )
    }
}

export default MyChannelDashboard