import React from 'react' 
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import farmerGrowthChart from '../Assets/img/farmerGrowthChartImg.png'

    // const distID = localStorage.getItem('distributorId');
    // const boostID = localStorage.getItem('companyIdBooster');
    // const parisID = localStorage.getItem('companyIdParis'); 
  
    // const { data, error, isLoaded } = GetRequest(`///${distID}/${boostID}`);
     
// if (error !== null) {
//   return <Error data=" " />;
// }
// if (!isLoaded) {
//   return  <Loading />;
// } 
const FarmerAreaDashboard = () => {
    return (

        <>

            <section className="content">
                <div className="container-fluid FIArea"> 
                    <div className="row">
                        <div className="col-12">
                            <div className="card ">
                                <div className="card-header bg-info">
                                    <h5 className="card-title"> Taluka Wise Farmer </h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-3 col-sm-6 col-12">
                                            <div className="info-box">
                                                <span className="info-box-icon bg-info"><i className="fa fa-male"></i></span>
                                                <div className="info-box-content">
                                                    <span className="info-box-text">Paithan</span>
                                                    <span className="info-box-number">1,410</span>
                                                </div>

                                            </div>

                                        </div>

                                        <div className="col-md-3 col-sm-6 col-12">
                                            <div className="info-box">
                                                <span className="info-box-icon bg-success"><i className="fa fa-male"></i></span>
                                                <div className="info-box-content">
                                                    <span className="info-box-text">Sillod</span>
                                                    <span className="info-box-number">4,102</span>
                                                </div>

                                            </div>

                                        </div>

                                        <div className="col-md-3 col-sm-6 col-12">
                                            <div className="info-box">
                                                <span className="info-box-icon bg-warning"><i className="fa fa-male"></i></span>
                                                <div className="info-box-content">
                                                    <span className="info-box-text">Rajapur</span>
                                                    <span className="info-box-number">13,648</span>
                                                </div>

                                            </div>

                                        </div>

                                        <div className="col-md-3 col-sm-6 col-12">
                                            <div className="info-box">
                                                <span className="info-box-icon bg-danger"><i className="fa fa-male"></i></span>
                                                <div className="info-box-content">
                                                    <span className="info-box-text">Bhausawal</span>
                                                    <span className="info-box-number">3,139</span>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card ">
                                <div className="card-header bg-success">
                                    <h5 className="card-title"> Village Wise Farmer </h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-3 col-sm-6 col-12">
                                            <div className="info-box">
                                                <span className="info-box-icon bg-info"><i className="fa fa-male"></i></span>
                                                <div className="info-box-content">
                                                    <span className="info-box-text">Dharngao</span>
                                                    <span className="info-box-number">810</span>
                                                </div>

                                            </div>

                                        </div>

                                        <div className="col-md-3 col-sm-6 col-12">
                                            <div className="info-box">
                                                <span className="info-box-icon bg-success"><i className="fa fa-male"></i></span>
                                                <div className="info-box-content">
                                                    <span className="info-box-text">Rajura</span>
                                                    <span className="info-box-number">92</span>
                                                </div>

                                            </div>

                                        </div>

                                        <div className="col-md-3 col-sm-6 col-12">
                                            <div className="info-box">
                                                <span className="info-box-icon bg-warning"><i className="fa fa-male"></i></span>
                                                <div className="info-box-content">
                                                    <span className="info-box-text">Tarnoli</span>
                                                    <span className="info-box-number">148</span>
                                                </div>

                                            </div>

                                        </div>

                                        <div className="col-md-3 col-sm-6 col-12">
                                            <div className="info-box">
                                                <span className="info-box-icon bg-danger"><i className="fa fa-male"></i></span>
                                                <div className="info-box-content">
                                                    <span className="info-box-text">Datala</span>
                                                    <span className="info-box-number">39</span>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card ">
                                <div className="card-header bg-success">
                                    <h5 className="card-title"> Farmer Growth In Your Area </h5>
                                </div>
                                <div className="card-body">
                                        <img src={farmerGrowthChart} className="img-fluid" alt='Farmer Growth In Your Area Chart' />
                                </div>
                            </div>
                        </div>
                    </div>
              
                </div>
            </section>
        </>
    )
}

export default FarmerAreaDashboard