import React from "react";
import PageTopHeadTitleBread from '../Components/PageTopHeadTitleBread'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import { useState } from 'react';
import axios from 'axios';


const InventoryDashboard = () => {

    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');

    const { data, error, isLoaded } = GetRequest(`/Inventory/GetInventory/${distID}`);
    const [inputs, setInputs] = useState({});


    if (error !== null) {
        return <Error data=" Inventory " />;
    }
    if (!isLoaded) {
        return <Loading />;
    } else {
        addFilter();
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }

    function setBasicDistAndProductItemId(inputs) {
        inputs.distributorId = distID;
        inputs.productItemId = document.getElementById('hdnSelectedProductItemId').value;
    }


    const handleAddnewInventorySubmit = (event) => {

        event.preventDefault();
        if (Object.keys(inputs).length !== 0) {
            setBasicDistAndProductItemId(inputs);
            axios.post("https://dpapi.boosterparis.in/api/Inventory/UpdateInventoryForProduct", inputs, {
                headers: {
                    "Cache-Control": "no-cache",
                    "Access-Control-Allow-Origin": "*",
                    'Authorization': localStorage.getItem("loginToken"),
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        document.getElementById('hdnSelectedProductItemId').value = '';
                        document.getElementById('qty').value = '';
                        document.getElementById('batch').value = '';
                        document.getElementById('date').value = '';
                        document.getElementById("titleAddStock").innerText = "Add Stock";
                        // tostDismissAll();
                        // notifySuccessAddChanel();
                        //  setSelectVal(response.data.result.id);

                    }
                }).catch((response) => {
                    // if(response.response.status === 401){  
                    //     window.location.replace("/Logout");
                    //  }
                    console.log(response.statusText);
                });
        } else {

        }
    }

    function addFilter() {
        setTimeout(function () {
            let list = document.querySelectorAll('.listfilterhead');
            let itemBox = document.querySelectorAll('.itemfiltered');

            for (let i = 0; i < list.length; i++) {
                list[i].addEventListener('click', function () {
                    for (let j = 0; j < list.length; j++) {
                        list[j].classList.remove('active');
                    }
                    this.classList.add('active');

                    let dataFilter = this.getAttribute('data-filter');

                    for (let k = 0; k < itemBox.length; k++) {
                        itemBox[k].classList.remove('active');
                        itemBox[k].classList.add('hide');

                        if (itemBox[k].getAttribute('data-item') == dataFilter || dataFilter == "all") {
                            itemBox[k].classList.remove('hide');
                            itemBox[k].classList.add('active');
                        }
                    }
                })
            }
        }, 1000);
    }

    function addnewStockForItems(vItemId) {
        document.getElementById('hdnSelectedProductItemId').value = vItemId;
        var vItemName = document.getElementById('hdnItemName_' + vItemId).value;
        document.getElementById("titleAddStock").innerText = "Add Stock For " + vItemName;
        // alert(vItemName);
    }

    return (
        <>
            <section className="content">
                <input type="hidden" id='hdnSelectedProductItemId' />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card noUI" id="inventoryPanelUI">
                                <div className="card-header">
                                    <button className="form-control flBtn listfilterhead active" data-filter="all"> All </button>
                                    {
                                        data.result.map((item, index) => (
                                            <>
                                                <button className="form-control flBtn listfilterhead" data-filter={item.id} id={item.id}> {item.name}  </button>
                                            </>
                                        ))
                                    }

                                    <div className="dropdown NOfilter">
                                        {/* <a href type="button" className="dropdown-toggle" data-bs-toggle="dropdown">
                                            Filter
                                        </a> */}
                                        <ul className="dropdown-menu">
                                            <input className="form-control" id="searchProductFilter" type="text" placeholder="Search Product.." />
                                            <li><hr className="dropdown-divider"></hr></li>
                                            {
                                                data.result.map((item, index) => (
                                                    <>
                                                        <li><a className="dropdown-item listfilterhead" href data-filter={item.id} id={item.id}> {item.name}  </a></li>
                                                    </>
                                                ))
                                            }

                                        </ul>
                                    </div>

                                </div>
                                <div className="card-body">
                                    <div id="accordion">
                                        {
                                            data.result && data.result.map((itemDt, index) => (
                                                <>
                                                    {
                                                        itemDt.productLines && itemDt.productLines.map((items, index) => (
                                                            <div className="card itemfiltered" data-item={itemDt.id} id={itemDt.id}>
                                                                <div className="card-header" data-bs-toggle="collapse" href={`#collapse${items.id}`}>


                                                                    <label className="pHeadName">{items.name}</label>

                                                                    <a className="btn dp-right-mt5" >
                                                                        <i className="fa fa-angle-double-down"></i>
                                                                        <i className="fa fa-angle-double-up"></i>
                                                                    </a>
                                                                </div>
                                                                <div id={`collapse${items.id}`} className="collapse" data-bs-parent="#accordion">
                                                                    <div className="card-body">
                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                {
                                                                                    items.productItems && items.productItems.map((itd, index) => (
                                                                                        <>
                                                                                            <div id="accordionBatchWise">
                                                                                                <div className="card">
                                                                                                    <div className="card-header" data-bs-toggle="collapse" href={`#btw-${itd.id}`}>
                                                                                                        <a className="btn" >
                                                                                                            {itd.name}  Min Qty - {itd.currentQuantity} {itd.minInventoryQtyUnit} /  {itd.minInventoryQty} {itd.minInventoryQtyUnit}
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <div id={`btw-${itd.id}`} className="collapse" data-bs-parent="#accordionBatchWise">
                                                                                                        <div className="card-body">
                                                                                                            <div className="row">
                                                                                                                <div className="col-md-4 col-5">
                                                                                                                </div>
                                                                                                                <div className="col-md-8 col-7">
                                                                                                                    <input type="hidden" id={`hdnItemName_${itd.id}`} value={`${itd.name}`} />
                                                                                                                    <a href className="btn btn-primary btn-sm btnInvT" data-bs-toggle="modal" data-bs-target="#addStockModal" id={itd.id} onClick={() => { addnewStockForItems(`${itd.id}`); }}>Add New Stock Entry</a>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="row">
                                                                                                                {
                                                                                                                    itd.batchWiseInventory && itd.batchWiseInventory.map((btw, index) => (
                                                                                                                        <div className="col-md-4">
                                                                                                                            <div className="card ">
                                                                                                                                <div className="card-header bg-danger">
                                                                                                                                    <h5 className="card-title"> Batch -  {btw.batchName} </h5>
                                                                                                                                </div>
                                                                                                                                <div className="card-body">
                                                                                                                                    <div className="row">
                                                                                                                                        <div className="col-md-4 col-5">
                                                                                                                                            <h6>  Current Stock </h6>
                                                                                                                                        </div>
                                                                                                                                        <div className="col-md-8 col-7">
                                                                                                                                            <h6 className="description-header"><i className="fa fa-minus mcui" aria-hidden="true"></i><input type="text" value={btw.currentQuantity} className="form-control ftvw" /><i className="fa fa-plus mcui" aria-hidden="true"></i></h6>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="row">
                                                                                                                                        <div className="col-md-4 col-5">
                                                                                                                                            <h6> Expiring In  </h6>
                                                                                                                                        </div>
                                                                                                                                        <div className="col-md-8 col-7">
                                                                                                                                            <h6>    {btw.expiringInDays} Days  </h6>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="row">
                                                                                                                                        <div className="col-md-4 col-5">
                                                                                                                                            <h6>  Expiry Date  </h6>
                                                                                                                                        </div>
                                                                                                                                        <div className="col-md-8 col-7">
                                                                                                                                            <h6> {btw.expiryDateStr}   </h6>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="row">
                                                                                                                                        <div className="col-md-4 col-5">
                                                                                                                                            <h6>   Stock Last Updated </h6>
                                                                                                                                        </div>
                                                                                                                                        <div className="col-md-8 col-7">
                                                                                                                                            <h6>  {btw.lastUpdatedOnStr}  </h6>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="row">
                                                                                                                                        <div className="col-md-4 col-5">
                                                                                                                                        </div>
                                                                                                                                        <div className="col-md-8 col-7">
                                                                                                                                            <a href className="btn btn-primary btn-sm btnInvT" data-bs-toggle="modal" data-bs-target="#addStockModal" id={itd.id}  >Add New Stock Entry</a>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    ))
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        ))
                                                    }
                                                </>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="addStockModal">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h6 className="modal-title" id='titleAddStock'>Add Stock </h6>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                                </div>
                                <div className="modal-body">
                                    <form onSubmit={handleAddnewInventorySubmit} noValidate>
                                        <div className="mb-3 mt-3">
                                            <label for="Product Quntity" className="form-label">Qty :</label>
                                            <input type="text" id="qty" name="qty" value={inputs.Name} onChange={handleChange} required className="form-control" placeholder="Enter Product Quntity" />
                                        </div>
                                        <div className="mb-3 mt-3">
                                            <label for="Batch " className="form-label">Stock   :</label>
                                            <input type="text" id="batch" className="form-control" onChange={handleChange} placeholder="Enter Batch " name="batch" />
                                        </div>
                                        <div className="mb-3 mt-3">
                                            <label for="Date" className="form-label">Date :</label>
                                            <input type="date" id="date" className="form-control" name="date" onChange={handleChange} />
                                        </div>
                                        <div className="md-foot">
                                            <br />
                                            <input type="submit" className="btn btn-primary btn-100 " data-bs-dismiss="modal" value="Add" />
                                            <button type="button" className="btn btn-danger btn-100 dp-right" data-bs-dismiss="modal">Close</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default InventoryDashboard;