import React from 'react'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'

const Tips = () => {

    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');

    const { data, error, isLoaded } = GetRequest(`/Tip/GetTiplist`);


    if (error !== null) {
        return <Error data="Tips " />;
    }
    if (!isLoaded) {
        return <Loading />;
    }
 
  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row"> 
            <div className="col-md-12">
              <div className="card  card-primary">
                <div className="card-header ">
                  <h3 className="card-title"> 
                    टिप्स / संक्षिप्त शिकवण
                  </h3>
                </div>
                <div className="card-body no-padding">
                {
                    data.result.map((item, index) => (

                      <div className={`callout ${index % 2 === 0 ? 'callout-success' : 'callout-danger'}`} id={item.id}>
                        <h5   className={`setTtleHead ${index % 2 === 0 ? 'bg-success' : 'bg-danger'}`}> {item.title} </h5> 
                        <p>
                           {item.description}
                        </p>
                    </div>

                  ))
                } 
                 
                </div> 

              </div>
            </div> 

          </div>
        </div>
      </section>
    </>
  )
}

export default Tips