import React from 'react'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import AllRunningSchemes from './AllRunningSchemes'
import { Link } from 'react-router-dom'

const ExpireSoonSchemes = () => {

    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');

    const { data, error, isLoaded } = GetRequest(`/Scheme/GetSchemesExpiringTodayTomorrow/${distID}`);
    
    if (error !== null) {
        return <Error data=" All Running Schemes " />;
    }
    if (!isLoaded) {
        return <Loading />;
    }

    if (data.result.length !== 0) {
        return (
            <>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card noUI schemDtsUI">
                                    <div className="card-header"> 
                                        <button className="form-control flBtn listfilterhead active" data-filter="11"> Shcemes Expiring Today/Tomorrow </button>
                                        <button className="form-control flBtn listfilterhead" data-filter="all"> All Running Schemes </button>
                                    </div>
                                    <div className="card-body">
                                        <div id="accordion">
                                            {
                                                data.result && data.result.map((item, index) => (
                                                    <div className="card card-primary itemfiltered active" data-item={item.id} id={item.id}>
                                                        <div className="card-header"  data-bs-toggle="collapse" href={`#collapse${item.id}`}>

                                                            <label className="pHeadName">{item.name}</label>

                                                            <a className="btn dp-right-mt5">
                                                                <i className="fa fa-angle-double-down"></i>
                                                                <i className="fa fa-angle-double-up"></i>
                                                            </a>
                                                        </div>
                                                        <div id={`collapse${item.id}`} className="collapse" data-bs-parent="#accordion">
                                                            <div className="card-body dtss">
                                                                {/* <div> <label className="pHeadName">{item.name}</label> </div> */}
                                                                <div className="row ">
                                                                    <div className="col-sm-2 col2">
                                                                        Active Till : {item.toDateString}
                                                                    </div>
                                                                    <div className="col-sm-2 col2">
                                                                        {item.productCategoryName}
                                                                    </div>

                                                                    <div className="col-sm-4  col4">
                                                                        {
                                                                            item.schemeDTODateDetailsItems && item.schemeDTODateDetailsItems.map((items, index) => (
                                                                                
                                                                                <div className='discRow'>
                                                                                    {items.fromDateString} To {items.toDateString}
                                                                                    - Discount : <b>{items.discountAmount}%</b>  
                                                                                    <br />
                                                                                </div>
                                                                            ))
                                                                        }
                                                                        <Link to={`/SchemesDetails/${item.id}`} className='btn btn-success btn-sm ' id={item.id}>View Details </Link>
                                                                    </div>
                                                                    <div className="col-sm-4 col4">
                                                                        {
                                                                            item.schemeDTODocumentDetailsItems && item.schemeDTODocumentDetailsItems.map((itdocs, index) => (
                                                                                 
                                                                                 <div className='discRow'>
                                                                                    {itdocs.documentType} :  {itdocs.schemeDocumentName} 
                                                                                    <a href={itdocs.schemeDocumentURL} target='_blank' title='Download File'> <i className="fa fa-file-pdf-o downPdfLk"></i></a>
                                                                                    <br />
                                                                                </div>

                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                ))
                                            }
                                            <AllRunningSchemes />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    } else {

        return (
            <>

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-danger">
                                <div className="card-header">
                                    <h3 className="card-title"> Schemes  </h3>    
                                </div>

                                <div className="card-body">
                                    <h4 className="NoDataAvailableText"> No Data Available </h4>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </section>
        </>
               

        )

    }
}

export default ExpireSoonSchemes