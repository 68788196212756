import React from 'react'

const ProductNeverOrder = () => {
    return (
        <>
            <section className="content">
                <div className="container-fluid">
                    <h1 className='comesoon'> Coming Soon! </h1>
                </div>
            </section>
            <section className="content hide">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                Crop Protection
                            </h3>


                        </div>
                        <div className="card-body">
                            <a href> Article 1</a>
                            <br />
                            <a href> Article 2</a>

                        </div>

                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                Crop Growth
                            </h3>


                        </div>
                        <div className="card-body">
                            <a href> Article 1</a>
                            <br />
                            <a href> Article 2</a>

                        </div>
                    </div>

                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                Soil Conditions
                            </h3>

                        </div>
                        <div className="card-body">
                            <a href> Article 1</a>
                            <br />
                            <a href> Article 2</a>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProductNeverOrder