import React from 'react'
import PageTopHeadTitleBread from '../Components/PageTopHeadTitleBread' 
import FormStepper from './FormStepper' 
import { Outlet } from 'react-router-dom' 
import { ToastContainer, toast } from 'react-toastify';

const PaymentDashboard = () => {
  setTimeout(function(){
    const checkUserType = localStorage.getItem('userType');
    if(checkUserType == 'BPUser') {
      document.getElementById('distributorNavList').classList.add('hide');
      document.getElementById('bpUserNavList').classList.remove('hide');
    } 
  }, 1000);
 
  return (
     <> 
       <section className="content">
            <div className="container-fluid"> 
                <div className="row">
                    <FormStepper />
                </div> 
                <div className="row"> 
                    <Outlet></Outlet>
                </div>
                <div style={{display:'none'}}>
                    <input type="hidden" name='paymentId' id="paymentIdResData" className="form-control"  
                    value="" />
                    <input type="hidden" name='amountPaid' id="amountPaidResData" className="form-control"  
                    value="" />  
                      <input type="hidden" name='totalePayValueDis' id="totalePayValueDis" className="form-control"  
                    value="" />
                    <input type="hidden" name='amountPaid' id="advPayValueDis" className="form-control"  
                    value="" />  
                  
                </div>
             
            </div>
        </section>
     </> 
  )
}

export default PaymentDashboard