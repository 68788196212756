import React from "react";
import PageTopHeadTitleBread from '../Components/PageTopHeadTitleBread'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'

const Articals = () => {

    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');

    const { data, error, isLoaded } = GetRequest(`/Article/GetArticlelist`);


    if (error !== null) {
        return <Error data="Article " />;
    }
    if (!isLoaded) {
        return <Loading />;
    }

    return (
        <>
            <section className="content">
                <div className="container-fluid">
                    <div className="row"> 
                    {
                        data.result.map((item, index) => (
                            <>
                            <div className="col-md-6"> 
                                <div className={`card ${index % 2 === 0 ? 'card-warning' : 'card-primary'}`} id={item.id}>
                                    <div className="card-header">
                                        <h3 className="card-title">
                                           {item.title}
                                        </h3> 
                                    </div>
                                    <div className="card-body">
                                        <p>
                                        {item.description}
                                       </p>
                                       <p>
                                        <b> Author : - {item.authorName}</b> 
                                        <p> Created Date :-  {item.createdOn.slice(0, 9)}   </p>
                                       </p>
                                    </div>

                                </div>
                                </div>
                            </>
                        ))
                    } 
                    </div>
                </div>
            </section>
        </>
    )
}

export default Articals